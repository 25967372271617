import { gsap } from 'gsap';

export function initializeCountdown() {
    // Set the date for December 19th at 7pm EST
    const countDownDate = new Date('2024-12-19T19:00:00-05:00').getTime();
    let countdownInterval;

    function updateCountdown() {
        const now = new Date().getTime();
        const distance = countDownDate - now;

        // Time calculations
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Update the countdown display
        const countdownEl = document.getElementById('countdown');
        if (countdownEl) {
            const timeStrings = [
                days > 0 ? `${days}d` : null,
                `${hours.toString().padStart(2, '0')}h`,
                `${minutes.toString().padStart(2, '0')}m`,
                `${seconds.toString().padStart(2, '0')}s`
            ].filter(Boolean);

            countdownEl.innerHTML = timeStrings.map(time => 
                `<span class="countdown-segment">${time}</span>`
            ).join(' ');

            // Pulse animation on seconds change
            gsap.to('.countdown-segment:last-child', {
                scale: 1.1,
                duration: 0.2,
                yoyo: true,
                repeat: 1,
                ease: "power2.inOut"
            });
        }

        if (distance < 0) {
            if (countdownInterval) {
                clearInterval(countdownInterval);
            }
            if (countdownEl) {
                countdownEl.innerHTML = "🍯 HONEY DROP IS LIVE! APE IN NOW! 🚀";
                gsap.to(countdownEl, {
                    scale: 1.1,
                    duration: 0.5,
                    repeat: -1,
                    yoyo: true,
                    ease: "power1.inOut"
                });
            }
        }
    }

    // Update the countdown every second
    updateCountdown();
    countdownInterval = setInterval(updateCountdown, 1000);

    // Cleanup on page change
    return () => {
        if (countdownInterval) {
            clearInterval(countdownInterval);
        }
    };
}
