import barba from '@barba/core';
import { gsap } from 'gsap';

export function initializePageTransitions() {
    barba.init({
        transitions: [{
            name: 'opacity-transition',
            leave(data) {
                return gsap.to(data.current.container, {
                    opacity: 0,
                    y: 50,
                    duration: 0.5
                });
            },
            enter(data) {
                return gsap.from(data.next.container, {
                    opacity: 0,
                    y: 50,
                    duration: 0.5
                });
            }
        }]
    });

    // Add hover effects and animations
    const initializeAnimations = () => {
        // Card hover animations
        const cards = document.querySelectorAll('.hover\\:scale-115');
        
        cards.forEach(card => {
            card.addEventListener('mouseover', () => {
                gsap.to(card, {
                    scale: 1.05,
                    duration: 0.3,
                    ease: 'power2.out'
                });
            });
            
            card.addEventListener('mouseout', () => {
                gsap.to(card, {
                    scale: 1,
                    duration: 0.3,
                    ease: 'power2.out'
                });
            });
        });

        // Initial animation for hero section
        gsap.from('.text-6xl', {
            opacity: 0,
            y: -50,
            duration: 1,
            ease: 'power3.out'
        });

        gsap.from('.grid-cols-1 > div', {
            opacity: 0,
            y: 50,
            duration: 0.8,
            stagger: 0.2,
            ease: 'power3.out'
        });
    };

    // Initialize animations on page load and after Barba transitions
    document.addEventListener('DOMContentLoaded', initializeAnimations);
    barba.hooks.after(() => {
        // initializeAnimations();
    });

    // Handle navigation
    document.addEventListener('click', (e) => {
        const link = e.target.closest('a');
        if (link && link.href.startsWith(window.location.origin)) {
            e.preventDefault();
            barba.go(link.href);
        }
    });
}
