export type ToastType = 'success' | 'error' | 'info' | 'warning';

interface ToastOptions {
  duration?: number;
  position?: 'top' | 'bottom';
}

/**
 * Show a toast notification
 * @param message Message to display
 * @param type Toast type (success, error, info, warning)
 * @param options Additional options
 */
export function showToast(
  message: string,
  type: ToastType,
  options: ToastOptions = {}
): void {
  const { duration = 3000, position = 'top' } = options;

  // Create toast element
  const toast = document.createElement('div');
  toast.className = `fixed ${position}-4 left-1/2 transform -translate-x-1/2 px-6 py-3 rounded-lg shadow-lg text-white z-50`;
  
  // Set background color based on type
  switch (type) {
    case 'success':
      toast.classList.add('bg-green-500');
      break;
    case 'error':
      toast.classList.add('bg-red-500');
      break;
    case 'warning':
      toast.classList.add('bg-yellow-500');
      break;
    case 'info':
      toast.classList.add('bg-blue-500');
      break;
  }

  // Add message
  toast.textContent = message;

  // Add to document
  document.body.appendChild(toast);

  // Animate in
  requestAnimationFrame(() => {
    toast.style.opacity = '0';
    toast.style.transition = 'opacity 0.3s ease-in-out';
    requestAnimationFrame(() => {
      toast.style.opacity = '1';
    });
  });

  // Remove after duration
  setTimeout(() => {
    toast.style.opacity = '0';
    setTimeout(() => {
      document.body.removeChild(toast);
    }, 300);
  }, duration);
}
