export function honeyPage() {
    return {
        solanaAddress: '',
        addressError: '',
        isProcessing: false,
        isMinting: false,
        hasBee: false,
        connections: [],
        dancePatterns: [],
        honeyPots: [],

        init() {
            // Check if user has a bee if they previously minted one
            if (localStorage.getItem('hasBee')) {
                this.hasBee = true;
            }
            
            // Load saved connections and patterns
            this.connections = JSON.parse(localStorage.getItem('honeyConnections') || '[]');
            this.dancePatterns = JSON.parse(localStorage.getItem('dancePatterns') || '[]');
            this.honeyPots = JSON.parse(localStorage.getItem('honeyPots') || '[]');
            
            // Setup AR event listeners
            this.setupARListeners();
        },

        setupARListeners() {
            window.addEventListener('arNetworkConnection', (e) => {
                this.handleNetworkConnection(e.detail);
            });
            
            window.addEventListener('arHoneyPotFound', (e) => {
                this.handleHoneyPotDiscovery(e.detail);
            });
            
            window.addEventListener('arDancePattern', (e) => {
                this.handleDancePattern(e.detail);
            });
        },

        async handleNetworkConnection(connectionData) {
            try {
                const response = await fetch('/api/process-honey-drop', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        type: 'connection',
                        data: connectionData,
                        address: this.solanaAddress
                    })
                });

                if (!response.ok) throw new Error('Failed to process connection');

                const result = await response.json();
                this.connections.push(connectionData);
                localStorage.setItem('honeyConnections', JSON.stringify(this.connections));
                
                this.showToast(`🐝 Connected! Earned ${result.reward} $HONEY`, 'success');
            } catch (error) {
                console.error('Connection error:', error);
                this.showToast(error.message, 'error');
            }
        },

        async handleHoneyPotDiscovery(potData) {
            try {
                const response = await fetch('/api/process-honey-drop', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        type: 'pot',
                        data: potData,
                        address: this.solanaAddress
                    })
                });

                if (!response.ok) throw new Error('Failed to process honey pot');

                const result = await response.json();
                this.honeyPots.push(potData);
                localStorage.setItem('honeyPots', JSON.stringify(this.honeyPots));
                
                this.showToast(`🍯 Sweet find! Earned ${result.reward} $HONEY`, 'success');
            } catch (error) {
                console.error('Honey pot error:', error);
                this.showToast(error.message, 'error');
            }
        },

        async handleDancePattern(patternData) {
            try {
                const response = await fetch('/api/process-honey-drop', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        type: 'dance',
                        data: patternData,
                        address: this.solanaAddress
                    })
                });

                if (!response.ok) throw new Error('Failed to process dance pattern');

                const result = await response.json();
                this.dancePatterns.push(patternData);
                localStorage.setItem('dancePatterns', JSON.stringify(this.dancePatterns));
                
                this.showToast(`💃 Nice moves! Earned ${result.reward} $HONEY`, 'success');
            } catch (error) {
                console.error('Dance pattern error:', error);
                this.showToast(error.message, 'error');
            }
        },

        validateAddress(address) {
            // Basic Solana address validation
            if (!address) {
                return 'Please enter a Solana address';
            }
            if (!/^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(address)) {
                return 'Invalid Solana address format';
            }
            return '';
        },

        async requestHoneyDrop() {
            this.addressError = this.validateAddress(this.solanaAddress);
            if (this.addressError) return;

            this.isProcessing = true;

            try {
                // Check M3M3 LP status
                const lpResponse = await fetch('/api/check-m3m3-lp', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });
                
                const lpStatus = await lpResponse.json();
                if (!lpResponse.ok) throw new Error('Failed to check liquidity status');

                // Send honey drop with LP multiplier
                const response = await fetch('/api/send-honey-drop', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        address: this.solanaAddress,
                        lpMultiplier: lpStatus.multiplier
                    })
                });

                const result = await response.json();
                if (!response.ok) throw new Error(result.error || 'Failed to send honey drop');

                this.showToast(`🍯 ${result.amount} $HONEY sent to your wallet!`, 'success');
                this.solanaAddress = ''; // Clear the input
            } catch (error) {
                console.error('Error sending honey drop:', error);
                this.showToast(error.message, 'error');
            } finally {
                this.isProcessing = false;
            }
        },

        async mintBee() {
            if (this.hasBee) {
                this.showToast('You already have a bee!', 'info');
                return;
            }

            if (!this.solanaAddress) {
                this.showToast('Please enter your Solana address first', 'error');
                return;
            }

            this.isMinting = true;

            try {
                // Call backend to mint bee
                const response = await fetch('/api/mint-bee', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        walletAddress: this.solanaAddress
                    })
                });

                if (!response.ok) throw new Error('Failed to mint bee');

                const result = await response.json();
                
                // Update bee status
                this.hasBee = true;
                localStorage.setItem('hasBee', 'true');
                
                // Show success message with link
                this.showToast(`Bee minted successfully! View your bee at: cseas.fun/t/${result.tokenId}`, 'success');

                // Redirect to bee page
                window.location.href = `/t/${result.tokenId}`;
            } catch (error) {
                console.error('Error minting bee:', error);
                this.showToast('Error minting bee', 'error');
            } finally {
                this.isMinting = false;
            }
        },

        showToast(message, type = 'info') {
            const toast = document.createElement('div');
            toast.className = `alert alert-${type}`;
            toast.innerHTML = message;
            
            const container = document.getElementById('toast-container');
            container?.appendChild(toast);
            
            setTimeout(() => {
                toast.remove();
            }, 3000);
        }
    };
}
