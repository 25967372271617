    import Alpine from 'alpinejs';

export function treePage() {
    return {
        email: '',
        showEmailInput: true,
        showEmail: false,
        sessionActive: false,
        treeWallet: null,
        hasCSEAS: true,
        isStartingSession: false,
        isMinting: false,
        isTreeMinted: false,
        mintProgress: 0,
        mintStatus: '',
        decorations: [],
        savedTrees: {},
        selectedTree: null,
        activeTree: null,
        hasDecorations: false,
        isSharing: false,
        shareUrl: '',
        copied: false,
        treeId: null,

        selectedWallet: null,
        walletOptions: [
            { id: 'handcash', name: 'HandCash', icon: '💳' },
            { id: 'unisat', name: 'Unisat', icon: '🌟' }
        ],
        showWalletModal: false,
        isWalletConnecting: false,

        async init() {
            // Get token ID from URL
            const urlParams = new URLSearchParams(window.location.search);
            this.tokenId = urlParams.get('id') || '';

            // Watch for wallet connection changes
            this.$watch('$store.wallet.isConnected', (isConnected) => {
                if (isConnected && this.tokenId) {
                    this.loadTreeStats();
                    this.loadRealityTokens();
                }
            });

            // Initial checks if wallet is connected
            if (this.$store.wallet.isConnected) {
                this.loadTreeStats();
                this.loadRealityTokens();
            }

            // Check for existing session
            const session = localStorage.getItem('treeSession');
            if (session) {
                const { email, wallet } = JSON.parse(session);
                this.email = email;
                this.treeWallet = wallet;
                this.showEmailInput = false;
                this.showEmail = true;
                this.sessionActive = true;
            }

            // Check for CSEAS balance
            if (this.treeWallet) {
                await this.checkCSEASBalance();
            }

            // Load saved trees and tree ID from localStorage
            const savedTrees = localStorage.getItem('savedTrees');
            const savedTreeId = localStorage.getItem('currentTreeId');
            
            if (savedTrees) {
                this.savedTrees = JSON.parse(savedTrees);
                this.hasDecorations = Object.keys(this.savedTrees).length > 0;
                if (this.hasDecorations) {
                    const lastTree = Object.entries(this.savedTrees)[0];
                    this.selectTree(lastTree[0], lastTree[1]);
                }
            }

            if (savedTreeId) {
                this.treeId = savedTreeId;
                this.isTreeMinted = true;
            }

            // Setup WebSocket for mint status updates
            this.setupMintStatusSocket();
        },

        openWalletModal() {
            this.showWalletModal = true;
        },

        async connectWallet(walletId) {
            this.isWalletConnecting = true;
            this.selectedWallet = walletId;

            try {
                if (walletId === 'handcash') {
                    await this.$store.wallet.connectHandCash();
                    this.showToast('HandCash wallet connected! We will help manage your BSV tokens.', 'success');
                } else if (walletId === 'unisat') {
                    await this.$store.wallet.connectUnisat();
                    this.showToast('Unisat wallet connected!', 'success');
                }

                this.showWalletModal = false;
                await this.loadRealityTokens();
            } catch (error) {
                console.error('Error connecting wallet:', error);
                this.showToast('Failed to connect wallet', 'error');
            } finally {
                this.isWalletConnecting = false;
            }
        },

        async copyWallet() {
            try {
                await navigator.clipboard.writeText(this.treeWallet);
                this.copied = true;
                setTimeout(() => {
                    this.copied = false;
                }, 2000);
                this.showToast('Wallet address copied!', 'success');
            } catch (error) {
                console.error('Failed to copy wallet:', error);
                this.showToast('Failed to copy wallet address', 'error');
            }
        },

        async loadRealityTokens() {
            try {
                // Load user's reality tokens and BSV balance
                const tokens = await this.$store.realityTokens.getBalances();
                // Update UI with token balances
            } catch (error) {
                console.error('Error loading reality tokens:', error);
            }
        },

        handleFileUpload(event) {
            const file = event.target.files[0];
            if (!file) return;

            // Check file type
            if (!file.type.startsWith('image/') && !file.type.startsWith('video/')) {
                this.showToast('Please upload an image or video file', 'error');
                return;
            }

            this.uploadedFile = file;
            
            // Create preview URL
            if (this.previewUrl) {
                URL.revokeObjectURL(this.previewUrl);
            }
            this.previewUrl = URL.createObjectURL(file);
        },

        handleDragOver(event) {
            event.preventDefault();
            event.target.classList.add('dragging');
        },

        handleDragLeave(event) {
            event.preventDefault();
            event.target.classList.remove('dragging');
        },

        handleDrop(event) {
            event.preventDefault();
            event.target.classList.remove('dragging');
            
            const file = event.dataTransfer.files[0];
            if (!file) return;

            // Check file type
            if (!file.type.startsWith('image/') && !file.type.startsWith('video/')) {
                this.showToast('Please upload an image or video file', 'error');
                return;
            }

            this.uploadedFile = file;
            
            // Create preview URL
            if (this.previewUrl) {
                URL.revokeObjectURL(this.previewUrl);
            }
            this.previewUrl = URL.createObjectURL(file);
        },

        async startSession(e) {
            e.preventDefault();
            this.isStartingSession = true;

            try {
                const response = await fetch('/api/tree-session', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ email: this.email })
                });

                if (!response.ok) throw new Error('Failed to start session');

                const { wallet } = await response.json();
                this.treeWallet = wallet;
                
                // Save session
                localStorage.setItem('treeSession', JSON.stringify({
                    email: this.email,
                    wallet: wallet
                }));

                this.showEmailInput = false;
                this.showEmail = true;
                this.sessionActive = true;
                
                this.showToast('Session started successfully! 🌲', 'success');
            } catch (error) {
                console.error('Error starting session:', error);
                this.showToast('Failed to start session', 'error');
            } finally {
                this.isStartingSession = false;
            }
        },

        async checkCSEASBalance() {
            try {
                const response = await fetch(`/api/check-cseas-balance?wallet=${this.treeWallet}`);
                if (!response.ok) throw new Error('Failed to check balance');

                const { hasBalance } = await response.json();
                this.hasCSEAS = hasBalance;
            } catch (error) {
                console.error('Error checking CSEAS balance:', error);
            }
        },

        handleNewTree() {
            if (confirm('Start a new tree? This will clear your current decorations.')) {
                // Clear active tree
                this.activeTree = null;
                this.selectedTree = null;
                // Clear decorations
                this.decorations = [];
                this.hasDecorations = false;
                // Reset mint status
                this.isTreeMinted = false;
                this.mintProgress = 0;
                this.mintStatus = '';
                // Clear local storage
                localStorage.removeItem('currentTreeId');
                localStorage.removeItem('treeState');
                // Show success message
                this.showToast('Started a new tree! 🌱', 'success');
            }
        },

        selectTree(name, tree) {
            this.selectedTree = name;
            this.activeTree = tree;
            this.decorations = tree.decorations;
            this.hasDecorations = true;
            this.isTreeMinted = tree.minted || false;
        },

        setupMintStatusSocket() {
            const ws = new WebSocket(`wss://${window.location.host}/ws/mint-status`);
            
            ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                
                if (data.type === 'progress') {
                    this.mintProgress = data.progress;
                    this.mintStatus = data.status;
                } else if (data.type === 'complete') {
                    this.isMinting = false;
                    this.isTreeMinted = true;
                    this.mintProgress = 100;
                    this.mintStatus = 'Tree successfully minted!';
                    this.showToast('Your tree has been minted! 🎄', 'success');
                } else if (data.type === 'error') {
                    this.isMinting = false;
                    this.mintStatus = 'Error minting tree';
                    this.showToast('Failed to mint tree: ' + data.error, 'error');
                }
            };

            ws.onerror = (error) => {
                console.error('WebSocket error:', error);
                this.showToast('Error connecting to mint status updates', 'error');
            };
        },

        async mintTree() {
            if (!this.hasDecorations) {
                this.showToast('Please decorate your tree first!', 'error');
                return;
            }

            this.isMinting = true;
            this.mintProgress = 0;
            this.mintStatus = 'Starting mint process...';

            try {
                const response = await fetch('/api/mint-decorated-tree', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        wallet: this.treeWallet,
                        tree: {
                            name: this.selectedTree,
                            decorations: this.decorations
                        }
                    })
                });

                if (!response.ok) throw new Error('Failed to start minting process');

                const data = await response.json();
                if (data.success && data.treeId) {
                    this.treeId = data.treeId;
                    localStorage.setItem('currentTreeId', data.treeId);
                    this.mintStatus = 'Minting in progress...';
                    this.showToast(`Your tree ID is: ${data.treeId}`, 'success');
                } else {
                    throw new Error('Invalid response from server');
                }
                // WebSocket will handle progress updates
            } catch (error) {
                console.error('Error minting tree:', error);
                this.isMinting = false;
                this.mintStatus = 'Error starting mint process';
                this.showToast('Failed to start minting process', 'error');
            }
        },

        async loadTreeStats() {
            try {
                const response = await fetch(`/api/tree-stats?tokenId=${this.tokenId}`);
                if (!response.ok) throw new Error('Failed to load tree stats');

                const stats = await response.json();
                this.treeStats = stats;
            } catch (error) {
                console.error('Error loading tree stats:', error);
                this.showToast('Error loading tree stats', 'error');
            }
        },

        async shareTree() {
            if (!this.hasDecorations) {
                this.showToast('Please decorate your tree first!', 'error');
                return;
            }

            this.isSharing = true;
            try {
                const response = await fetch('/api/create-tree-link', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        treeData: {
                            decorations: this.decorations
                        }
                    })
                });

                if (!response.ok) throw new Error('Failed to create share link');

                const { success, url } = await response.json();
                if (!success) throw new Error('Invalid response from server');

                this.shareUrl = window.location.origin + url;
                await navigator.clipboard.writeText(this.shareUrl);
                this.showToast('Share link copied to clipboard! 🎄', 'success');
            } catch (error) {
                console.error('Error sharing tree:', error);
                this.showToast('Failed to create share link', 'error');
            } finally {
                this.isSharing = false;
            }
        },

        showToast(message, type = 'info') {
            const toast = document.createElement('div');
            toast.className = `alert alert-${type}`;
            toast.innerHTML = message;
            
            const container = document.getElementById('toast-container');
            container?.appendChild(toast);
            
            setTimeout(() => {
                toast.remove();
            }, 3000);
        }
    };
}
