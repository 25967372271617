import { showToast } from './utils/toast';

// Component state and methods interface
interface TreeDecPageState {
  email: string;
  treeWallet: string | null;
  sessionActive: boolean;
  hasCSEAS: boolean;
  showEmailInput: boolean;
  showEmail: boolean;
  copied: boolean;
  isSaving: boolean;
  showWalletModal: boolean;
  isWalletConnecting: boolean;
  selectedWallet: string | null;
  isStartingSession: boolean;
  decorations: Array<{x: number; y: number; ornament: string}>;
  hasDecorations: boolean;
  isMinting: boolean;
  savedTrees: { [key: string]: any };
  selectedTree: string | null;
  init(): void;
  checkExistingSession(): Promise<void>;
  startSession(): Promise<void>;
  checkCSEASBalance(): Promise<void>;
  copyWallet(): Promise<void>;
  connectWallet(type: string): Promise<void>;
  loadDecorations(): void;
  loadSavedTrees(): void;
  selectTree(name: string, tree: any): void;
  mintTree(): Promise<void>;
}

export const treeDecPage = (): TreeDecPageState => {
    const state: TreeDecPageState = {
        email: '',
        treeWallet: null,
        sessionActive: false,
        hasCSEAS: true,
        showEmailInput: true,
        showEmail: false,
        copied: false,
        isSaving: false,
        showWalletModal: false,
        isWalletConnecting: false,
        selectedWallet: null,
        isStartingSession: false,
        decorations: [],
        hasDecorations: false,
        isMinting: false,
        savedTrees: {},
        selectedTree: null,

        init() {
            this.loadSavedTrees();
            this.checkExistingSession();
        },

        loadSavedTrees() {
            this.savedTrees = JSON.parse(localStorage.getItem('savedTrees') || '{}');
            if (Object.keys(this.savedTrees).length > 0) {
                // Load the most recent tree by default
                const lastTreeName = Object.keys(this.savedTrees)[Object.keys(this.savedTrees).length - 1];
                this.selectTree(lastTreeName, this.savedTrees[lastTreeName]);
            }
        },

        selectTree(name: string, tree: any) {
            this.selectedTree = name;
            this.decorations = tree.decorations;
            this.hasDecorations = true;
        },

        async checkExistingSession() {
            try {
                const response = await fetch('/api/tree-session');
                const data = await response.json();
                
                if (data.active) {
                    this.sessionActive = true;
                    this.email = data.email;
                    this.treeWallet = data.wallet;
                    this.checkCSEASBalance();
                }
            } catch (error) {
                console.error('Failed to check session:', error);
            }
        },

        async startSession() {
            if (!this.email) return;
            
            this.isStartingSession = true;
            try {
                const response = await fetch('/api/tree-session', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: this.email }),
                });
                
                const data = await response.json();
                if (data.success) {
                    this.sessionActive = true;
                    this.treeWallet = data.wallet;
                    this.showEmailInput = false;
                    this.showEmail = true;
                    showToast('Session started! Check your email for the wallet address.', 'success');
                } else {
                    showToast('Failed to start session. Please try again.', 'error');
                }
            } catch (error) {
                console.error('Failed to start session:', error);
                showToast('Failed to start session. Please try again.', 'error');
            } finally {
                this.isStartingSession = false;
            }
        },

        async checkCSEASBalance() {
            if (!this.treeWallet) return;
            
            try {
                const response = await fetch(`/api/check-cseas-balance?wallet=${this.treeWallet}`);
                const data = await response.json();
                this.hasCSEAS = data.hasBalance;
                
                if (this.hasCSEAS) {
                    this.loadDecorations();
                }
            } catch (error) {
                console.error('Failed to check CSEAS balance:', error);
            }
        },

        async copyWallet() {
            if (!this.treeWallet) return;
            
            try {
                await navigator.clipboard.writeText(this.treeWallet);
                this.copied = true;
                setTimeout(() => this.copied = false, 2000);
                showToast('Wallet address copied!', 'success');
            } catch (error) {
                console.error('Failed to copy wallet:', error);
                showToast('Failed to copy wallet address', 'error');
            }
        },

        async connectWallet(type: string) {
            this.isWalletConnecting = true;
            this.selectedWallet = type;
            
            try {
                if (type === 'handcash') {
                    await this.$store.wallet.connectHandCash();
                } else if (type === 'unisat') {
                    await this.$store.wallet.connectUnisat();
                }
                
                this.showWalletModal = false;
                showToast('Wallet connected successfully!', 'success');
            } catch (error) {
                console.error('Failed to connect wallet:', error);
                showToast('Failed to connect wallet. Please try again.', 'error');
            } finally {
                this.isWalletConnecting = false;
                this.selectedWallet = null;
            }
        },

        loadDecorations() {
            if (Object.keys(this.savedTrees).length > 0) {
                // Load the most recent tree by default
                const lastTreeName = Object.keys(this.savedTrees)[Object.keys(this.savedTrees).length - 1];
                this.selectTree(lastTreeName, this.savedTrees[lastTreeName]);
            } else {
                this.hasDecorations = false;
            }
        },

        async mintTree() {
            if (!this.$store.wallet.isConnected) {
                this.showWalletModal = true;
                return;
            }
            
            this.isMinting = true;
            try {
                const response = await fetch('/api/mint-decorated-tree', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        wallet: this.treeWallet,
                        decorations: this.decorations
                    }),
                });
                
                const data = await response.json();
                if (data.success) {
                    showToast('Tree minted successfully!', 'success');
                    window.location.href = '/treestake';
                } else {
                    showToast('Failed to mint tree. Please try again.', 'error');
                }
            } catch (error) {
                console.error('Failed to mint tree:', error);
                showToast('Failed to mint tree. Please try again.', 'error');
            } finally {
                this.isMinting = false;
            }
        },
    };

    return state;
};
