import { pods } from './pods/index.js'

export const podPage = () => ({
    podData: {},
    
    async init() {
        // Get pod ID from URL
        const urlParams = new URLSearchParams(window.location.search)
        const podId = urlParams.get('pod') || 'honey_bee' // Default to honey_bee if no pod specified
        
        // Load pod data
        if (pods[podId]) {
            this.podData = await pods[podId]()
        } else {
            console.error(`Pod ${podId} not found`)
            // Redirect to 404 or home page
            window.location.href = '/'
        }
    }
})
