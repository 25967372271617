export default function moonshotProgress() {
    return {
        progressPercentage: 0,
        solRaised: 0,
        solTarget: 500,
        marketCapUsd: 0,
        isLoading: true,
        error: null,

        async init() {
            await this.updateProgress();
            // Update every 5 minutes
            setInterval(() => this.updateProgress(), 5 * 60 * 1000);
        },

        async updateProgress() {
            try {
                const response = await fetch('https://api.dexscreener.com/latest/dex/tokens/92dLaNpWekXPkqiFXKmBgEGjfzZJ5bC79AKeLaqAjXUo');
                const data = await response.json();

                const pair = data.pairs[0];
                const priceUsd = parseFloat(pair.priceUsd);
                const priceSol = parseFloat(pair.priceNative);

                // Calculate total SOL raised (assuming 500 SOL target)
                const totalSupply = 527000000; // Total supply of CSEAS tokens
                this.solRaised = totalSupply * priceSol;
                this.progressPercentage = (this.solRaised / this.solTarget) * 100;
                this.marketCapUsd = totalSupply * priceUsd;
                this.isLoading = false;
                this.error = null;
            } catch (error) {
                console.error('Error fetching DEXScreener data:', error);
                this.error = 'Error loading progress';
                this.isLoading = false;
            }
        }
    };
}
