import { showToast } from './utils/toast';
import type { ToastType } from './utils/toast';
import L from 'leaflet';

interface CrowdfundingStats {
    totalEggs: number;
    totalFunders: number;
    currentFunding: number;
    targetFunding: number;
}

interface Egg {
    id: string;
    name: string;
    description: string;
    reward: number;
    lat: number;
    lng: number;
    found: boolean;
    placedBy: string;
    targetFunding: number;
    currentFunding: number;
    funders: string[];
    fundingEndDate: string | null;
}

interface NewEgg {
    name: string;
    description: string;
    reward: number;
    lat: number;
    lng: number;
    targetFunding: number;
    fundingEndDate: string | null;
}

export function eggPage() {
    return {
        map: null as L.Map | null,
        markers: {} as { [key: string]: L.Marker },
        placementMode: false,
        showEggModal: false,
        selectedEgg: null as Egg | null,
        newEgg: {
            name: '',
            description: '',
            reward: 100,
            lat: 0,
            lng: 0,
            targetFunding: 0,
            fundingEndDate: null
        } as NewEgg,
        isPlacing: false,
        isFinding: false,
        isNearEgg: false,
        filter: 'all',
        totalEggs: 0,
        activeHunters: 0,
        totalRewards: 0,
        crowdfunding: null as CrowdfundingStats | null,
        userLocation: null as L.LatLng | null,
        locationWatcher: null as number | null,

        async init() {
            // Initialize map
            this.map = L.map('egg-map').setView([0, 0], 2);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '© OpenStreetMap contributors'
            }).addTo(this.map);

            // Watch user location
            if ("geolocation" in navigator) {
                this.locationWatcher = navigator.geolocation.watchPosition(
                    (position) => {
                        this.userLocation = L.latLng(position.coords.latitude, position.coords.longitude);
                        if (this.map && !this.map.getBounds().contains(this.userLocation)) {
                            this.map.setView(this.userLocation, 15);
                        }
                        this.checkProximityToEggs();
                    },
                    (error) => {
                        console.error('Geolocation error:', error);
                        showToast('error', 'Unable to get your location');
                    }
                );
            }

            // Load initial data
            await this.loadEggs();
            await this.loadStats();

            // Set up map click handler
            this.map.on('click', (e) => {
                if (this.placementMode) {
                    this.newEgg.lat = e.latlng.lat;
                    this.newEgg.lng = e.latlng.lng;
                    this.showEggModal = true;
                }
            });

            // Update stats periodically
            setInterval(() => this.loadStats(), 30000);
        },

        async loadEggs() {
            try {
                const response = await fetch('/api/eggs');
                const eggs = await response.json() as Egg[];
                
                // Clear existing markers
                (Object.values(this.markers) as L.Marker[]).forEach(marker => marker.remove());
                this.markers = {};

                // Add new markers
                eggs.forEach((egg: Egg) => {
                    if (this.filter === 'found' && !egg.found) return;
                    if (this.filter === 'unfound' && egg.found) return;

                    const markerHtml = egg.found ? '🥚✨' : 
                        (egg.targetFunding > 0 && egg.currentFunding < egg.targetFunding) ? '🥚💰' : '🥚';

                    const marker: L.Marker = L.marker([egg.lat, egg.lng], {
                        icon: L.divIcon({
                            html: markerHtml,
                            className: 'egg-marker'
                        })
                    }).addTo(this.map!);

                    marker.on('click', () => {
                        this.selectedEgg = egg;
                        this.showEggModal = true;
                        this.checkProximityToEggs();
                    });

                    this.markers[egg.id] = marker;
                });
            } catch (error) {
                console.error('Error loading eggs:', error);
                showToast('error', 'Failed to load eggs');
            }
        },

        async loadStats() {
            try {
                const response = await fetch('/api/egg-stats');
                const stats = await response.json();
                this.totalEggs = stats.totalEggs;
                this.activeHunters = stats.activeHunters;
                this.totalRewards = stats.totalRewards;
                this.crowdfunding = stats.crowdfunding || {
                    totalEggs: 0,
                    totalFunders: 0,
                    currentFunding: 0,
                    targetFunding: 0
                };
            } catch (error) {
                console.error('Error loading stats:', error);
            }
        },

        togglePlacementMode() {
            this.placementMode = !this.placementMode;
            if (this.placementMode) {
                showToast('info', 'Click on the map to place an egg');
            }
        },

        async placeEgg() {
            if (!this.newEgg.name || !this.newEgg.description || this.newEgg.reward <= 0) {
                showToast('error', 'Please fill out all fields');
                return;
            }

            if (this.newEgg.targetFunding > 0 && !this.newEgg.fundingEndDate) {
                showToast('error', 'Please set a funding end date for crowdfunded eggs');
                return;
            }

            this.isPlacing = true;
            try {
                const response = await fetch('/api/place-egg', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(this.newEgg)
                });

                if (!response.ok) throw new Error('Failed to place egg');

                showToast('success', 'Egg placed successfully!');
                this.showEggModal = false;
                this.placementMode = false;
                this.newEgg = {
                    name: '',
                    description: '',
                    reward: 100,
                    lat: 0,
                    lng: 0
                };

                await this.loadEggs();
                await this.loadStats();
            } catch (error) {
                console.error('Error placing egg:', error);
                showToast('error', 'Failed to place egg');
            } finally {
                this.isPlacing = false;
            }
        },

        checkProximityToEggs() {
            if (!this.userLocation || !this.selectedEgg) return;

            const eggLocation = L.latLng(this.selectedEgg.lat, this.selectedEgg.lng);
            const distance = this.userLocation.distanceTo(eggLocation);
            
            // Check if user is within 10 meters of the egg
            this.isNearEgg = distance <= 10;
        },

        async findEgg() {
            if (!this.selectedEgg || !this.isNearEgg) return;

            if (this.selectedEgg.targetFunding > 0 && this.selectedEgg.currentFunding < this.selectedEgg.targetFunding) {
                showToast('error', 'This egg needs to be fully funded before it can be found');
                return;
            }

            this.isFinding = true;
            try {
                const response = await fetch(`/api/find-egg/${this.selectedEgg.id}`, {
                    method: 'POST'
                });

                if (!response.ok) throw new Error('Failed to find egg');

                const result = await response.json();
                showToast('success', `Congratulations! You found the egg and earned ${result.reward} $🥚!`);
                this.showEggModal = false;
                
                await this.loadEggs();
                await this.loadStats();
            } catch (error) {
                console.error('Error finding egg:', error);
                showToast('error', 'Failed to find egg');
            } finally {
                this.isFinding = false;
            }
        },

        setFilter(newFilter: string) {
            this.filter = newFilter;
            this.loadEggs();
        },

        async fundEgg(amount: number) {
            if (!this.selectedEgg) return;

            try {
                const response = await fetch(`/api/fund-egg/${this.selectedEgg.id}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ amount })
                });

                if (!response.ok) throw new Error('Failed to fund egg');

                const result = await response.json();
                showToast('success', `Successfully contributed ${amount} $CSEAS to the egg!`);
                
                await this.loadEggs();
                await this.loadStats();
            } catch (error) {
                console.error('Error funding egg:', error);
                showToast('error', 'Failed to fund egg');
            }
        },

        getRemainingFunding() {
            if (!this.selectedEgg || !this.selectedEgg.targetFunding) return 0;
            return this.selectedEgg.targetFunding - this.selectedEgg.currentFunding;
        },

        getFundingProgress() {
            if (!this.selectedEgg || !this.selectedEgg.targetFunding) return 0;
            return (this.selectedEgg.currentFunding / this.selectedEgg.targetFunding) * 100;
        },

        async disconnect() {
            if (this.locationWatcher) {
                navigator.geolocation.clearWatch(this.locationWatcher);
            }
            if (this.map) {
                this.map.remove();
            }
        }
    };
}
