export function beePage() {
    return {
        availableBalance: 0,
        stashedBalance: 0,
        receiveMethod: 'tap',
        dropAmount: 0,
        recipientAddress: '',
        isDropping: false,
        qrCode: null,
        tokenId: '',

        init() {
            // Get token ID from URL
            const urlParams = new URLSearchParams(window.location.search);
            this.tokenId = urlParams.get('id') || 'No Token ID';

            // Initialize QR code if wallet is connected
            if (this.$store.wallet.isConnected) {
                this.generateQRCode();
            }

            // Watch for wallet connection changes
            this.$watch('$store.wallet.isConnected', (isConnected) => {
                if (isConnected) {
                    this.generateQRCode();
                    this.checkBalances();
                }
            });

            // Initial balance check
            this.checkBalances();
        },

        formatBalance(amount) {
            return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 6
            }).format(amount);
        },

        generateQRCode() {
            if (!this.$store.wallet.address) return;

            try {
                const qrCanvas = document.getElementById('qrcode');
                if (!qrCanvas) return;

                // Clear previous QR code
                qrCanvas.innerHTML = '';

                // Create new QR code with styling
                this.qrCode = new QRCodeStyling({
                    width: 300,
                    height: 300,
                    type: "svg",
                    data: this.$store.wallet.address,
                    dotsOptions: {
                        color: "#f59e0b",
                        type: "rounded",
                        gradient: {
                            type: "radial",
                            colorStops: [
                                { offset: 0, color: "#fbbf24" },
                                { offset: 0.5, color: "#f59e0b" },
                                { offset: 1, color: "#d97706" }
                            ]
                        }
                    },
                    cornersDotOptions: {
                        type: "dot",
                        color: "#fbbf24"
                    },
                    backgroundOptions: {
                        color: "#ffffff",
                    },
                    imageOptions: {
                        crossOrigin: "anonymous",
                        margin: 10,
                        imageSize: 0.4
                    },
                    qrOptions: {
                        errorCorrectionLevel: 'H'
                    },
                    image: "/images/cseaspin.gif"
                });

                this.qrCode.append(qrCanvas);
            } catch (error) {
                console.error('Error generating QR code:', error);
            }
        },

        async checkBalances() {
            if (!this.$store.wallet.isConnected) return;

            try {
                // Get balances from reality coins store
                const balances = await this.$store.realityCoins.getBalances();
                this.availableBalance = balances.available || 0;
                this.stashedBalance = balances.stashed || 0;
            } catch (error) {
                console.error('Error checking balances:', error);
                this.showToast('Error checking balances', 'error');
            }
        },

        openReceiveModal() {
            const modal = document.getElementById('receive-modal');
            modal?.showModal();
            // Generate QR code when modal opens if wallet is connected
            if (this.$store.wallet.isConnected) {
                this.generateQRCode();
            }
        },

        openDropModal() {
            const modal = document.getElementById('drop-modal');
            modal?.showModal();
        },

        navigateToSwap() {
            window.location.href = '/swap';
        },

        async activateTapToReceive() {
            if (!this.$store.wallet.isConnected) {
                this.showToast('Please connect your wallet first', 'error');
                return;
            }

            try {
                // Check if Web NFC is available
                if ('NDEFReader' in window) {
                    const ndef = new NDEFReader();
                    await ndef.scan();

                    this.showToast('Tap enabled! Touch your phone to a CSEAS tag', 'success');

                    ndef.addEventListener('reading', ({ message }) => {
                        // Process NFC tag data
                        for (const record of message.records) {
                            if (record.recordType === 'url') {
                                const decoder = new TextDecoder();
                                const url = decoder.decode(record.data);
                                
                                // Handle the CSEAS tag URL
                                if (url.includes('cseas.fun')) {
                                    this.processHoneyDrop(url);
                                }
                            }
                        }
                    });
                } else {
                    this.showToast('NFC is not supported on this device', 'error');
                }
            } catch (error) {
                console.error('Error activating NFC:', error);
                this.showToast('Error activating tap to receive', 'error');
            }
        },

        async processHoneyDrop(tagUrl) {
            try {
                // Call backend to process honey drop
                const response = await fetch('/api/process-honey-drop', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        tagUrl,
                        walletAddress: this.$store.wallet.address,
                        tokenId: this.tokenId
                    })
                });

                if (!response.ok) throw new Error('Failed to process honey drop');

                const result = await response.json();
                this.showToast(`Received ${result.amount} honey drops! 🍯`, 'success');
                
                // Update balances
                await this.checkBalances();
            } catch (error) {
                console.error('Error processing honey drop:', error);
                this.showToast('Error processing honey drop', 'error');
            }
        },

        async sendDrop() {
            if (!this.$store.wallet.isConnected) {
                this.showToast('Please connect your wallet first', 'error');
                return;
            }

            if (!this.dropAmount || !this.recipientAddress) {
                this.showToast('Please enter amount and recipient address', 'error');
                return;
            }

            this.isDropping = true;

            try {
                // Send honey drop using reality coins store
                await this.$store.realityCoins.transfer(
                    this.recipientAddress,
                    this.dropAmount
                );

                this.showToast(`Successfully sent ${this.dropAmount} honey drops! 🍯`, 'success');
                
                // Reset form and update balances
                this.dropAmount = 0;
                this.recipientAddress = '';
                await this.checkBalances();

                // Close modal
                const modal = document.getElementById('drop-modal');
                modal?.close();
            } catch (error) {
                console.error('Error sending drop:', error);
                this.showToast('Error sending honey drop', 'error');
            } finally {
                this.isDropping = false;
            }
        },

        async copyAddress() {
            if (!this.$store.wallet.address) return;

            try {
                await navigator.clipboard.writeText(this.$store.wallet.address);
                this.showToast('Address copied to clipboard! 📋', 'success');
            } catch (error) {
                console.error('Error copying address:', error);
                this.showToast('Error copying address', 'error');
            }
        },

        showToast(message, type = 'info') {
            const toast = document.createElement('div');
            toast.className = `alert alert-${type}`;
            toast.innerHTML = message;
            
            const container = document.getElementById('toast-container');
            container?.appendChild(toast);
            
            setTimeout(() => {
                toast.remove();
            }, 3000);
        }
    };
}
