import type { RealityCoin } from './types';

export const REALITY_COINS: RealityCoin[] = [
  {
    id: 'cseas',
    emoji: '🌊',
    name: 'CurrentSeas',
    symbol: 'CSEAS',
    stakingUrl: '/dao',
    buyUrl: 'https://dexscreener.com/solana/92dlanpwekxpkqifxkmbgegjfzzj5bc79akelaqajxuo',
    description: 'Reserve token of the CurrentSeas Community DAO',
    tags: ['primary', 'governance', 'reserve'],
    status: 'LIVE',
    metadata: {
      totalSupply: 100000000,
      decimals: 18
    }
  },
  {
    id: 'honey',
    emoji: '🍯',
    name: 'Honey',
    symbol: 'HONEY',
    stakingUrl: '/honey',
    buyUrl: 'https://jup.ag/swap/So11111111111111111111111111111111111111112-SyBv9PrfMHjSpFfncb7AGiEwPYwDQ7YLFXuBcXfmoon',
    description: 'Reward token for ecosystem participation',
    tags: ['reward', 'ecosystem'],
    status: 'LIVE',
    metadata: {
      totalSupply: 500000,
      decimals: 18
    }
  },
  {
    id: 'redHeart',
    emoji: '❤️',
    name: 'Red Heart',
    symbol: 'RHEART',
    description: 'Love of the sea token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'blueHeart',
    emoji: '💙',
    name: 'Blue Heart',
    symbol: 'BHEART',
    description: 'Ocean heart token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'greenHeart',
    emoji: '💚',
    name: 'Green Heart',
    symbol: 'GHEART',
    description: 'Seaweed heart token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'purpleHeart',
    emoji: '💜',
    name: 'Purple Heart',
    symbol: 'PHEART',
    description: 'Deep sea heart token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'mermaid',
    emoji: '🧜‍♀️',
    name: 'Mermaid',
    symbol: 'MERMAID',
    description: 'Mythical sea maiden token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'coral',
    emoji: '🪸',
    name: 'Coral',
    symbol: 'CORAL',
    description: 'Ocean reef token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'whale',
    emoji: '🐋',
    name: 'Whale',
    symbol: 'WHALE',
    description: 'Ocean giant token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'fish',
    emoji: '🐟',
    name: 'Fish',
    symbol: 'FISH',
    description: 'Swimming fish token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'tropicalFish',
    emoji: '🐠',
    name: 'Tropical Fish',
    symbol: 'TFISH',
    description: 'Colorful reef fish token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'blowfish',
    emoji: '🐡',
    name: 'Blowfish',
    symbol: 'PUFFER',
    description: 'Pufferfish token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'shark',
    emoji: '🦈',
    name: 'Shark',
    symbol: 'SHARK',
    description: 'Ocean predator token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'shell',
    emoji: '🐚',
    name: 'Shell',
    symbol: 'SHELL',
    description: 'Sea shell token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'jellyfish',
    emoji: '🪼',
    name: 'Jellyfish',
    symbol: 'JELLY',
    description: 'Floating jellyfish token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'tree',
    emoji: '🎄',
    name: 'Tree',
    symbol: 'TREE',
    stakingUrl: '/tree',
    buyUrl: 'https://jup.ag/swap/So11111111111111111111111111111111111111112-AWtUyQoHGyrJLTChhNMrJu6p3TmoUtm69m5WG3nrmoon',
    description: 'Ecosystem growth and sustainability token',
    tags: ['utility', 'sustainability'],
    status: 'LIVE',
    metadata: {
      totalSupply: 250000,
      decimals: 18
    }
  },
  {
    id: 'santa',
    emoji: '🎅',
    name: 'Santa',
    symbol: 'SANTA',
    description: 'Christmas special token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'gift',
    emoji: '🎁',
    name: 'Gift',
    symbol: 'GIFT',
    description: 'Festive gift token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'candyCane',
    emoji: '🍬',
    name: 'Candy Cane',
    symbol: 'CANDY',
    description: 'Sweet Christmas treat token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'gingerbreadMan',
    emoji: '🥮',
    name: 'Gingerbread Man',
    symbol: 'GMAN',
    description: 'Spiced Christmas cookie token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'snowman',
    emoji: '☃️',
    name: 'Snowman',
    symbol: 'SNOW',
    description: 'Frosty Christmas friend token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'reindeer',
    emoji: '🦌',
    name: 'Reindeer',
    symbol: 'REIN',
    description: 'Santa\'s helper token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'elf',
    emoji: '🧝',
    name: 'Elf',
    symbol: 'ELF',
    description: 'Santa\'s little helper token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'bell',
    emoji: '🔔',
    name: 'Bell',
    symbol: 'BELL',
    description: 'Jingle bell token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'fireworks',
    emoji: '🎆',
    name: 'Fireworks',
    symbol: 'BOOM',
    description: 'New Year celebration token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'sparkles',
    emoji: '✨',
    name: 'Sparkles',
    symbol: 'SPARKLE',
    description: 'Holiday magic token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'hotChocolate',
    emoji: '☕',
    name: 'Hot Chocolate',
    symbol: 'COCOA',
    description: 'Warm winter drink token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'mittens',
    emoji: '🧤',
    name: 'Mittens',
    symbol: 'MITT',
    description: 'Warm winter mittens token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'scarf',
    emoji: '🧣',
    name: 'Scarf',
    symbol: 'SCARF',
    description: 'Cozy winter scarf token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'skates',
    emoji: '⛸️',
    name: 'Skates',
    symbol: 'SKATE',
    description: 'Ice skating fun token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'snowflake',
    emoji: '❄️',
    name: 'Snowflake',
    symbol: 'FLAKE',
    description: 'Unique winter snowflake token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'sled',
    emoji: '🛷',
    name: 'Sled',
    symbol: 'SLED',
    description: 'Winter sledding adventure token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'iceSkate',
    emoji: '⛸',
    name: 'Ice Skate',
    symbol: 'ICESKATE',
    description: 'Single ice skate token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'ornament',
    emoji: '🪅',
    name: 'Ornament',
    symbol: 'ORNAMENT',
    description: 'Christmas tree ornament token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'candle',
    emoji: '🕯️',
    name: 'Candle',
    symbol: 'CANDLE',
    description: 'Warm winter candle token',
    tags: ['seasonal', 'limited'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'anchor',
    emoji: '⚓',
    name: 'Anchor',
    symbol: 'ANCHOR',
    description: 'Nautical anchor token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'starfish',
    emoji: '🌟',
    name: 'Starfish',
    symbol: 'STAR',
    description: 'Sea star token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'wave',
    emoji: '🌊',
    name: 'Wave',
    symbol: 'WAVE',
    description: 'Ocean wave token',
    tags: ['sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'partyPopper',
    emoji: '🥳',
    name: 'Party Popper',
    symbol: 'PARTY',
    description: 'Celebration token',
    tags: ['common'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'fourLeafClover',
    emoji: '🍀',
    name: 'Four Leaf Clover',
    symbol: 'CLOVER',
    description: 'Good luck token',
    tags: ['common'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'mushroom',
    emoji: '🍄',
    name: 'Mushroom',
    symbol: 'SHROOM',
    description: 'Fungi token',
    tags: ['common'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'taco',
    emoji: '🌮',
    name: 'Taco',
    symbol: 'TACO',
    description: 'Delicious Mexican food token',
    tags: ['common'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'hamburger',
    emoji: '🍔',
    name: 'Hamburger',
    symbol: 'BURGER',
    description: 'Classic fast food token',
    tags: ['common'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'lightBulb',
    emoji: '💡',
    name: 'Light Bulb',
    symbol: 'BULB',
    description: 'Idea token',
    tags: ['common'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'moneyBag',
    emoji: '💰',
    name: 'Money Bag',
    symbol: 'MONEY',
    description: 'Wealth token',
    tags: ['common'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'gemStone',
    emoji: '💎',
    name: 'Gem Stone',
    symbol: 'GEM',
    description: 'Precious token',
    tags: ['common'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'rocket',
    emoji: '🚀',
    name: 'Rocket',
    symbol: 'ROCKET',
    description: 'To the moon token',
    tags: ['common'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'puzzlePiece',
    emoji: '🧩',
    name: 'Puzzle Piece',
    symbol: 'PUZZLE',
    description: 'Solution token',
    tags: ['common'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'poop',
    emoji: '💩',
    name: 'Poop',
    symbol: 'POOP',
    description: 'Silly fun token',
    tags: ['common', 'fun'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'tshirt',
    emoji: '👕',
    name: 'T-Shirt',
    symbol: 'SHIRT',
    description: 'Casual wear token',
    tags: ['clothing'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'jeans',
    emoji: '👖',
    name: 'Jeans',
    symbol: 'JEANS',
    description: 'Denim pants token',
    tags: ['clothing'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'dress',
    emoji: '👗',
    name: 'Dress',
    symbol: 'DRESS',
    description: 'Elegant dress token',
    tags: ['clothing'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'highHeel',
    emoji: '👠',
    name: 'High Heel',
    symbol: 'HEEL',
    description: 'Stylish shoe token',
    tags: ['clothing'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'boot',
    emoji: '👢',
    name: 'Boot',
    symbol: 'BOOT',
    description: 'Winter boot token',
    tags: ['clothing'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'wavingHand',
    emoji: '👋',
    name: 'Waving Hand',
    symbol: 'WHAND',
    description: 'Friendly greeting token',
    tags: ['gesture'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'thumbsUp',
    emoji: '👍',
    name: 'Thumbs Up',
    symbol: 'LIKE',
    description: 'Approval token',
    tags: ['gesture'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'thumbsDown',
    emoji: '👎',
    name: 'Thumbs Down',
    symbol: 'DISLIKE',
    description: 'Disapproval token',
    tags: ['gesture'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'peace',
    emoji: '✌️',
    name: 'Peace',
    symbol: 'PEACE',
    description: 'Peace sign token',
    tags: ['gesture'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'shaka',
    emoji: '🤙',
    name: 'Shaka',
    symbol: 'SHAKA',
    description: 'Hang loose token',
    tags: ['gesture'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'smile',
    emoji: '😊',
    name: 'Smile',
    symbol: 'SMILE',
    description: 'Happy face token',
    tags: ['face'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'cool',
    emoji: '😎',
    name: 'Cool Face',
    symbol: 'COOL',
    description: 'Sunglasses face token',
    tags: ['face'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'thinking',
    emoji: '🤔',
    name: 'Thinking Face',
    symbol: 'THINK',
    description: 'Contemplative face token',
    tags: ['face'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'sleeping',
    emoji: '😴',
    name: 'Sleeping Face',
    symbol: 'SLEEP',
    description: 'Sleeping face token',
    tags: ['face'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'moneyFace',
    emoji: '🤑',
    name: 'Money Face',
    symbol: 'RICH',
    description: 'Money mouth face token',
    tags: ['face'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'lion',
    emoji: '🦁',
    name: 'Lion',
    symbol: 'LION',
    description: 'King of jungle token',
    tags: ['animal'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'elephant',
    emoji: '🐘',
    name: 'Elephant',
    symbol: 'ELEPH',
    description: 'Elephant token',
    tags: ['animal'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'giraffe',
    emoji: '🦒',
    name: 'Giraffe',
    symbol: 'GIRAF',
    description: 'Tall giraffe token',
    tags: ['animal'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'fox',
    emoji: '🦊',
    name: 'Fox',
    symbol: 'FOX',
    description: 'Clever fox token',
    tags: ['animal'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'panda',
    emoji: '🐼',
    name: 'Panda',
    symbol: 'PANDA',
    description: 'Giant panda token',
    tags: ['animal'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'soccer',
    emoji: '⚽',
    name: 'Soccer Ball',
    symbol: 'SOCCER',
    description: 'Soccer ball token',
    tags: ['sport'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'basketball',
    emoji: '🏀',
    name: 'Basketball',
    symbol: 'BBALL',
    description: 'Basketball token',
    tags: ['sport'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'tennis',
    emoji: '🎾',
    name: 'Tennis',
    symbol: 'TENNIS',
    description: 'Tennis ball token',
    tags: ['sport'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'football',
    emoji: '🏈',
    name: 'Football',
    symbol: 'FBALL',
    description: 'American football token',
    tags: ['sport'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'baseball',
    emoji: '⚾',
    name: 'Baseball',
    symbol: 'BSBALL',
    description: 'Baseball token',
    tags: ['sport'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'sun',
    emoji: '☀️',
    name: 'Sun',
    symbol: 'SUN',
    description: 'Bright sun token',
    tags: ['weather'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'crescent',
    emoji: '🌙',
    name: 'Crescent Moon',
    symbol: 'MOON',
    description: 'Crescent moon token',
    tags: ['weather'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'glowingStar',
    emoji: '⭐',
    name: 'Glowing Star',
    symbol: 'GSTAR',
    description: 'Glowing star token',
    tags: ['weather'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'rainbow',
    emoji: '🌈',
    name: 'Rainbow',
    symbol: 'RBOW',
    description: 'Colorful rainbow token',
    tags: ['weather'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'storm',
    emoji: '⛈️',
    name: 'Storm',
    symbol: 'STORM',
    description: 'Thunder storm token',
    tags: ['weather'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'musicalNote',
    emoji: '🎵',
    name: 'Musical Note',
    symbol: 'NOTE',
    description: 'Musical note token',
    tags: ['music'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'guitar',
    emoji: '🎸',
    name: 'Guitar',
    symbol: 'GUITAR',
    description: 'Electric guitar token',
    tags: ['music'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'drum',
    emoji: '🥁',
    name: 'Drum',
    symbol: 'DRUM',
    description: 'Drum set token',
    tags: ['music'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'piano',
    emoji: '🎹',
    name: 'Piano',
    symbol: 'PIANO',
    description: 'Piano keyboard token',
    tags: ['music'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'trumpet',
    emoji: '🎺',
    name: 'Trumpet',
    symbol: 'TRUMP',
    description: 'Trumpet instrument token',
    tags: ['music'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'car',
    emoji: '🚗',
    name: 'Car',
    symbol: 'CAR',
    description: 'Red car token',
    tags: ['vehicle'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'motorcycle',
    emoji: '🏍️',
    name: 'Motorcycle',
    symbol: 'MOTO',
    description: 'Racing motorcycle token',
    tags: ['vehicle'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'helicopter',
    emoji: '🚁',
    name: 'Helicopter',
    symbol: 'HELI',
    description: 'Flying helicopter token',
    tags: ['vehicle'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'boat',
    emoji: '⛵',
    name: 'Sailboat',
    symbol: 'SAIL',
    description: 'Sailing boat token',
    tags: ['vehicle', 'sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'train',
    emoji: '🚂',
    name: 'Train',
    symbol: 'TRAIN',
    description: 'Steam locomotive token',
    tags: ['vehicle'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'laptop',
    emoji: '💻',
    name: 'Laptop',
    symbol: 'LAPTOP',
    description: 'Computer laptop token',
    tags: ['tech'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'smartphone',
    emoji: '📱',
    name: 'Smartphone',
    symbol: 'PHONE',
    description: 'Mobile phone token',
    tags: ['tech'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'gamepad',
    emoji: '🎮',
    name: 'Gamepad',
    symbol: 'GAME',
    description: 'Video game controller token',
    tags: ['tech'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'camera',
    emoji: '📷',
    name: 'Camera',
    symbol: 'CAM',
    description: 'Digital camera token',
    tags: ['tech'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'television',
    emoji: '📺',
    name: 'Television',
    symbol: 'TV',
    description: 'TV set token',
    tags: ['tech'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'unicorn',
    emoji: '🦄',
    name: 'Unicorn',
    symbol: 'UNI',
    description: 'Magical unicorn token',
    tags: ['fantasy'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'dragon',
    emoji: '🐉',
    name: 'Dragon',
    symbol: 'DRAGON',
    description: 'Mythical dragon token',
    tags: ['fantasy'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'crystal',
    emoji: '🔮',
    name: 'Crystal Ball',
    symbol: 'CRYSTAL',
    description: 'Mystical crystal ball token',
    tags: ['fantasy'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'fairy',
    emoji: '🧚',
    name: 'Fairy',
    symbol: 'FAIRY',
    description: 'Magical fairy token',
    tags: ['fantasy'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'wizard',
    emoji: '🧙‍♂️',
    name: 'Wizard',
    symbol: 'WIZ',
    description: 'Magical wizard token',
    tags: ['fantasy'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'apple',
    emoji: '🍎',
    name: 'Apple',
    symbol: 'APPLE',
    description: 'Red apple token',
    tags: ['fruit'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'banana',
    emoji: '🍌',
    name: 'Banana',
    symbol: 'BANANA',
    description: 'Yellow banana token',
    tags: ['fruit'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'grapes',
    emoji: '🍇',
    name: 'Grapes',
    symbol: 'GRAPE',
    description: 'Purple grapes token',
    tags: ['fruit'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'strawberry',
    emoji: '🍓',
    name: 'Strawberry',
    symbol: 'STRAW',
    description: 'Sweet strawberry token',
    tags: ['fruit'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'watermelon',
    emoji: '🍉',
    name: 'Watermelon',
    symbol: 'MELON',
    description: 'Juicy watermelon token',
    tags: ['fruit'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'aries',
    emoji: '♈',
    name: 'Aries',
    symbol: 'ARIES',
    description: 'Ram zodiac token',
    tags: ['zodiac'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'taurus',
    emoji: '♉',
    name: 'Taurus',
    symbol: 'TAUR',
    description: 'Bull zodiac token',
    tags: ['zodiac'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'gemini',
    emoji: '♊',
    name: 'Gemini',
    symbol: 'GMINI',
    description: 'Twins zodiac token',
    tags: ['zodiac'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'cancer',
    emoji: '♋',
    name: 'Cancer',
    symbol: 'CANC',
    description: 'Crab zodiac token',
    tags: ['zodiac'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'leo',
    emoji: '♌',
    name: 'Leo',
    symbol: 'LEO',
    description: 'Lion zodiac token',
    tags: ['zodiac'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'usaFlag',
    emoji: '🇺🇸',
    name: 'USA Flag',
    symbol: 'USA',
    description: 'American flag token',
    tags: ['flag'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'japanFlag',
    emoji: '🇯🇵',
    name: 'Japan Flag',
    symbol: 'JPN',
    description: 'Japanese flag token',
    tags: ['flag'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'ukFlag',
    emoji: '🇬🇧',
    name: 'UK Flag',
    symbol: 'GBR',
    description: 'British flag token',
    tags: ['flag'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'canadaFlag',
    emoji: '🇨🇦',
    name: 'Canada Flag',
    symbol: 'CAN',
    description: 'Canadian flag token',
    tags: ['flag'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'australiaFlag',
    emoji: '🇦🇺',
    name: 'Australia Flag',
    symbol: 'AUS',
    description: 'Australian flag token',
    tags: ['flag'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'surfing',
    emoji: '🏄',
    name: 'Surfing',
    symbol: 'SURF',
    description: 'Surfing activity token',
    tags: ['activity', 'sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'skiing',
    emoji: '⛷️',
    name: 'Skiing',
    symbol: 'SKI',
    description: 'Skiing activity token',
    tags: ['activity'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'climbing',
    emoji: '🧗',
    name: 'Climbing',
    symbol: 'CLIMB',
    description: 'Rock climbing token',
    tags: ['activity'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'swimming',
    emoji: '🏊',
    name: 'Swimming',
    symbol: 'SWIM',
    description: 'Swimming activity token',
    tags: ['activity', 'sea'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'cycling',
    emoji: '🚴',
    name: 'Cycling',
    symbol: 'CYCLE',
    description: 'Cycling activity token',
    tags: ['activity'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'mountain',
    emoji: '⛰️',
    name: 'Mountain',
    symbol: 'MTN',
    description: 'Mountain landscape token',
    tags: ['nature'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'volcano',
    emoji: '🌋',
    name: 'Volcano',
    symbol: 'VOLC',
    description: 'Active volcano token',
    tags: ['nature'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'desert',
    emoji: '🏜️',
    name: 'Desert',
    symbol: 'DESERT',
    description: 'Desert landscape token',
    tags: ['nature'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'forest',
    emoji: '🌲',
    name: 'Forest',
    symbol: 'FOREST',
    description: 'Forest landscape token',
    tags: ['nature'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'glacier',
    emoji: '🏔️',
    name: 'Glacier',
    symbol: 'GLCR',
    description: 'Snow-capped mountain token',
    tags: ['nature'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'hammer',
    emoji: '🔨',
    name: 'Hammer',
    symbol: 'HAMMER',
    description: 'Construction hammer token',
    tags: ['tool'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'wrench',
    emoji: '🔧',
    name: 'Wrench',
    symbol: 'WRENCH',
    description: 'Mechanical wrench token',
    tags: ['tool'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'screwdriver',
    emoji: '🪛',
    name: 'Screwdriver',
    symbol: 'SCREW',
    description: 'Screwdriver tool token',
    tags: ['tool'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'saw',
    emoji: '🪚',
    name: 'Saw',
    symbol: 'SAW',
    description: 'Carpentry saw token',
    tags: ['tool'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'toolbox',
    emoji: '🧰',
    name: 'Toolbox',
    symbol: 'TOOLS',
    description: 'Complete toolbox token',
    tags: ['tool'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'palette',
    emoji: '🎨',
    name: 'Palette',
    symbol: 'ART',
    description: 'Artist palette token',
    tags: ['art'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'paintbrush',
    emoji: '🖌️',
    name: 'Paintbrush',
    symbol: 'BRUSH',
    description: 'Artist paintbrush token',
    tags: ['art'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'crayon',
    emoji: '🖍️',
    name: 'Crayon',
    symbol: 'CRAYON',
    description: 'Coloring crayon token',
    tags: ['art'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'pencil',
    emoji: '✏️',
    name: 'Pencil',
    symbol: 'PENCIL',
    description: 'Drawing pencil token',
    tags: ['art'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'scissors',
    emoji: '✂️',
    name: 'Scissors',
    symbol: 'SCIS',
    description: 'Crafting scissors token',
    tags: ['art', 'tool'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'clock',
    emoji: '🕐',
    name: 'Clock',
    symbol: 'CLOCK',
    description: 'One o\'clock token',
    tags: ['time'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'watch',
    emoji: '⌚',
    name: 'Watch',
    symbol: 'WATCH',
    description: 'Wristwatch token',
    tags: ['time'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'calendar',
    emoji: '📅',
    name: 'Calendar',
    symbol: 'CAL',
    description: 'Calendar date token',
    tags: ['time'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'sandClock',
    emoji: '⏳',
    name: 'Sand Clock',
    symbol: 'SAND',
    description: 'Sand timer token',
    tags: ['time'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'bellClock',
    emoji: '🔔',
    name: 'Bell Clock',
    symbol: 'RING',
    description: 'Ringing alarm token',
    tags: ['time'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'sushi',
    emoji: '🍣',
    name: 'Sushi',
    symbol: 'SUSHI',
    description: 'Japanese sushi token',
    tags: ['food'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'pizza',
    emoji: '🍕',
    name: 'Pizza',
    symbol: 'PIZZA',
    description: 'Pizza slice token',
    tags: ['food'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'ramen',
    emoji: '🍜',
    name: 'Ramen',
    symbol: 'RAMEN',
    description: 'Noodle soup token',
    tags: ['food'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'iceCream',
    emoji: '🍦',
    name: 'Ice Cream',
    symbol: 'ICECREAM',
    description: 'Soft serve token',
    tags: ['food'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'coffee',
    emoji: '☕',
    name: 'Coffee',
    symbol: 'COFFEE',
    description: 'Hot coffee token',
    tags: ['food'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'sunflower',
    emoji: '🌻',
    name: 'Sunflower',
    symbol: 'FLOWER',
    description: 'Sunflower plant token',
    tags: ['plant'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'tulip',
    emoji: '🌷',
    name: 'Tulip',
    symbol: 'TULIP',
    description: 'Tulip flower token',
    tags: ['plant'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'hibiscus',
    emoji: '🌺',
    name: 'Hibiscus',
    symbol: 'HIBI',
    description: 'Hibiscus flower token',
    tags: ['plant'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'bonsai',
    emoji: '🎋',
    name: 'Bonsai',
    symbol: 'BONSAI',
    description: 'Bonsai tree token',
    tags: ['plant'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'bamboo',
    emoji: '🎍',
    name: 'Bamboo',
    symbol: 'BAMBOO',
    description: 'Bamboo plant token',
    tags: ['plant'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'dice',
    emoji: '🎲',
    name: 'Dice',
    symbol: 'DICE',
    description: 'Game dice token',
    tags: ['game'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'puzzle',
    emoji: '🧩',
    name: 'Puzzle',
    symbol: 'PUZZLE',
    description: 'Jigsaw puzzle token',
    tags: ['game'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'joystick',
    emoji: '🕹️',
    name: 'Joystick',
    symbol: 'ARCADE',
    description: 'Arcade joystick token',
    tags: ['game'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'bowling',
    emoji: '🎳',
    name: 'Bowling',
    symbol: 'BOWL',
    description: 'Bowling game token',
    tags: ['game'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'dart',
    emoji: '🎯',
    name: 'Dart',
    symbol: 'DART',
    description: 'Bullseye dart token',
    tags: ['game'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'mushroom',
    emoji: '🍄',
    name: 'Mushroom',
    symbol: 'SHROOM',
    description: 'Fungi token',
    tags: ['nature'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'wave',
    emoji: '🌊',
    name: 'Wave',
    symbol: 'WAVE',
    description: 'Ocean wave token',
    tags: ['nature'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'hand',
    emoji: '✋',
    name: 'Hand',
    symbol: 'HAND',
    description: 'Raised hand token',
    tags: ['gesture'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'moneyBag',
    emoji: '💰',
    name: 'Money Bag',
    symbol: 'MONEY',
    description: 'Wealth token',
    tags: ['finance'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'carrot',
    emoji: '🥕',
    name: 'Carrot',
    symbol: 'CARROT',
    description: 'Vegetable token',
    tags: ['food'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'santa',
    emoji: '🎅',
    name: 'Santa',
    symbol: 'SANTA',
    description: 'Christmas special token',
    tags: ['holiday'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'snowflake',
    emoji: '❄️',
    name: 'Snowflake',
    symbol: 'FLAKE',
    description: 'Winter snowflake token',
    tags: ['winter'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'balloon',
    emoji: '🎈',
    name: 'Balloon',
    symbol: 'BALLOON',
    description: 'Party decoration token',
    tags: ['decoration'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'cookie',
    emoji: '🍪',
    name: 'Cookie',
    symbol: 'COOKIE',
    description: 'Sweet treat token',
    tags: ['food'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'heart',
    emoji: '❤️',
    name: 'Heart',
    symbol: 'HEART',
    description: 'Love token',
    tags: ['love'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'angel',
    emoji: '👼',
    name: 'Angel',
    symbol: 'ANGEL',
    description: 'Heavenly being token',
    tags: ['fantasy'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'fox',
    emoji: '🦊',
    name: 'Fox',
    symbol: 'FOX',
    description: 'Clever animal token',
    tags: ['animal'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'soccer',
    emoji: '⚽',
    name: 'Soccer',
    symbol: 'SOCCER',
    description: 'Sports ball token',
    tags: ['sport'],
    status: 'SOON',
    metadata: {}
  },
  {
    id: 'dragon',
    emoji: '🐉',
    name: 'Dragon',
    symbol: 'DRAGON',
    description: 'Mythical creature token',
    tags: ['fantasy'],
    status: 'SOON',
    metadata: {}
  },
];

// Function to get a coin by ID
export function getCoinById(id: string): RealityCoin | undefined {
  return REALITY_COINS.find(coin => coin.id === id);
}

// Function to get all coins
export function getAllCoins(): RealityCoin[] {
  return REALITY_COINS;
}

// Function to get live coins
export function getLiveCoins(): RealityCoin[] {
  return REALITY_COINS.filter(coin => coin.status === 'LIVE');
}

// Function to get soon coins
export function getSoonCoins(): RealityCoin[] {
  return REALITY_COINS.filter(coin => coin.status === 'SOON');
}
