import type { RealityCoin } from './types';
import { REALITY_COINS, getLiveCoins, getSoonCoins } from './realityCoinsSeed';

class RealityCoinsManager {
  private coins: RealityCoin[] = REALITY_COINS;
  private coinsLoadedEvent = new CustomEvent('coins-loaded');
  private coinsErrorEvent = new CustomEvent('coins-load-error');

  constructor() {
    this.initializeCoins();
  }

  private async initializeCoins() {
    try {
      // In future, this could fetch from an API
      this.setupCoinsTable();
      document.dispatchEvent(this.coinsLoadedEvent);
    } catch (error) {
      console.warn('Failed to fetch coins, using local data', error);
      document.dispatchEvent(this.coinsErrorEvent);
    }
  }

  private getButtonStyle(coinId: string): string {
    switch (coinId) {
      case 'cseas':
        return 'bg-blue-500 hover:bg-blue-600';
      case 'honey':
      case 'tree':
        return 'bg-amber-500 hover:bg-amber-600';
      default:
        return 'bg-blue-500 hover:bg-blue-600';
    }
  }

  private renderLiveCoin(coin: RealityCoin): string {
    const buttonStyle = this.getButtonStyle(coin.id);
    const isReserveToken = coin.id === 'cseas';
    
    return `
      <tr class="hover:bg-white/5 transition-colors ${isReserveToken ? 'bg-blue-900/20' : ''}">
        <td>
          <div class="flex items-center gap-3">
            <span class="text-2xl">${coin.emoji}</span>
            <div>
              <div class="font-bold">${coin.name}</div>
              <div class="text-sm opacity-50">$${coin.symbol}</div>
            </div>
            ${isReserveToken ? '<span class="badge badge-info ml-2">Reserve Token</span>' : ''}
          </div>
        </td>
        <td class="font-mono">$0.---</td>
        <td><span class="text-red-400">--%</span></td>
        <td class="font-mono">$--</td>
        <td><span class="badge badge-success">LIVE</span></td>
        <td>
          <div class="flex gap-2">
            ${coin.stakingUrl ? `
              <a href="${coin.stakingUrl}" 
                 class="btn btn-sm ${buttonStyle} text-white border-none">
                <span class="mr-1">ℹ️</span>
                ${isReserveToken ? 'DAO' : 'Info'}
              </a>
            ` : ''}
            ${coin.buyUrl ? `
              <a href="${coin.buyUrl}" 
                 target="_blank"
                 class="btn btn-sm ${buttonStyle} text-white border-none">
                <span class="mr-1">🚀</span>
                Buy
              </a>
            ` : ''}
          </div>
        </td>
      </tr>
    `;
  }

  private renderSoonCoin(coin: RealityCoin): string {
    return `
      <tr class="hover:bg-white/5 transition-colors">
        <td>
          <div class="flex items-center gap-3">
            <span class="text-2xl">${coin.emoji}</span>
            <div>
              <div class="font-bold">${coin.name}</div>
              <div class="text-sm opacity-50">$${coin.symbol}</div>
            </div>
          </div>
        </td>
        <td class="font-mono">-</td>
        <td>-</td>
        <td class="font-mono">-</td>
        <td><span class="badge badge-ghost">SOON</span></td>
        <td>
          <button class="btn btn-sm btn-disabled">Coming Soon</button>
        </td>
      </tr>
    `;
  }

  public setupCoinsTable() {
    const tableBody = document.querySelector('#coins-table-body');
    if (!tableBody) {
      console.warn('Could not find coins table body');
      return;
    }

    console.log('Setting up coins table');

    // Clear existing rows
    tableBody.innerHTML = '';

    // Render live coins
    const liveCoins = this.getLiveCoins();
    console.log('Live coins:', liveCoins);
    liveCoins.forEach(coin => {
      tableBody.insertAdjacentHTML('beforeend', this.renderLiveCoin(coin));
    });

    // Render soon coins section
    const soonCoins = this.getSoonCoins();
    console.log('Soon coins:', soonCoins);
    if (soonCoins.length > 0) {
      // Add Christmas Collection header
      const soonHeader = `
        <tr class="hover:bg-white/5 transition-colors">
          <td colspan="6" class="text-center py-4">
            <div class="text-xl text-green-400 font-bold mb-2">🎄 Christmas Collection 🎅</div>
            <p class="text-white/80">Our festive collection of spatial coins launching soon!</p>
          </td>
        </tr>
      `;
      tableBody.insertAdjacentHTML('beforeend', soonHeader);

      // Render each upcoming coin
      soonCoins.forEach(coin => {
        tableBody.insertAdjacentHTML('beforeend', this.renderSoonCoin(coin));
      });
    }
  }

  public getLiveCoins(): RealityCoin[] {
    return getLiveCoins();
  }

  public getSoonCoins(): RealityCoin[] {
    return getSoonCoins();
  }

  public bindEvents() {
    document.addEventListener('coins-loaded', () => {
      console.log('Coins loaded successfully');
    });

    document.addEventListener('coins-load-error', () => {
      console.warn('Coins loaded from local data');
      this.setupCoinsTable();
    });
  }
}

// Initialize and export as named export 'realityCoins'
const manager = new RealityCoinsManager();
manager.bindEvents();

export const realityCoins = manager;
