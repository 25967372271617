export default async function getHoneyBeeData() {
    // In a real implementation, some of this data would come from an API
    return {
        id: 'honey_bee',
        title: 'Honey Bee Pod',
        subtitle: 'Sweet Rewards & Conservation',
        accentColor: '#FFA500', // Orange/honey color
        model: 'honey_bee.glb',
        
        // Recent activity feed
        activities: [
            {
                id: 1,
                title: 'New Honey Drop',
                description: 'Latest honey drop distributed to active stakers',
                timestamp: '2 hours ago',
                tags: ['#HoneyDrop', '#Staking', '#Rewards']
            },
            {
                id: 2,
                title: 'Conservation Milestone',
                description: 'Community funded 5 new beehive installations',
                timestamp: '1 day ago',
                tags: ['#SaveTheBees', '#Impact', '#Conservation']
            },
            {
                id: 3,
                title: 'Token Burn Event',
                description: 'Scheduled token burn completed - 1M $HONEY burned',
                timestamp: '3 days ago',
                tags: ['#TokenBurn', '#Deflationary', '#Tokenomics']
            }
        ],

        // Token statistics
        stats: {
            price: '250',
            holders: '1,247',
            volume: '45,000'
        },

        // Related tags for discovery
        relatedTags: [
            '#HoneyDrop',
            '#BeeConservation',
            '#Staking',
            '#PassiveIncome',
            '#Sustainability',
            '#GreenDefi',
            '#ImpactDAO'
        ]
    }
}
