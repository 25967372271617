// Divine haikus for the star generator
const haikus = [
    {
        lines: [
            "Stellar light above",
            "Tree branches reach for the stars",
            "Wealth flows eternal"
        ]
    },
    {
        lines: [
            "Cosmic dance of light",
            "Sacred trees await their stars",
            "Fortune blooms within"
        ]
    },
    {
        lines: [
            "Radiant star burns",
            "Blessed tree yearns for its crown",
            "Destiny unfolds"
        ]
    },
    {
        lines: [
            "Celestial glow",
            "Nature's bounty intertwined",
            "Riches from above"
        ]
    },
    {
        lines: [
            "Stars guide the pathway",
            "Trees reach up to touch their light",
            "Abundance flows down"
        ]
    }
];

// Star patterns and color schemes
const starPatterns = [
    { name: "Classic", weight: 0.4 },
    { name: "Spiral", weight: 0.2 },
    { name: "Nova", weight: 0.2 },
    { name: "Binary", weight: 0.1 },
    { name: "Pulsar", weight: 0.1 }
];

const colorSchemes = [
    { name: "Blue-White", colors: [0, 2.094, 4.189], weight: 0.3 },
    { name: "Red Giant", colors: [0, 1.047, 2.094], weight: 0.2 },
    { name: "Neutron", colors: [3.142, 4.189, 5.236], weight: 0.2 },
    { name: "Binary", colors: [1.571, 3.142, 4.712], weight: 0.15 },
    { name: "Nebula", colors: [2.618, 3.665, 4.712], weight: 0.15 }
];

// Shader sources
const vertexShaderSource = `
    attribute vec4 aPosition;
    void main() {
        gl_Position = aPosition;
    }
`;

const fragmentShaderSource = `
    precision mediump float;
    uniform float time;
    uniform vec2 resolution;
    uniform float uNumPoints;
    uniform float uSpeed;
    uniform float uIntensity;
    uniform float uColorShift;
    uniform float uRotationSpeed;
    uniform float uStarInfluence;
    uniform float uPulseRate;
    uniform float uSymmetry;
    uniform float uInnerRadius;
    uniform float uOuterGlow;
    uniform float uColorPhase;

    void main() {
        vec2 p = (gl_FragCoord.xy * 2.0 - resolution) / min(resolution.x, resolution.y);
        float n = time * uSpeed;
        float l = length(p);
        vec4 O = vec4(0.0, 0.0, 0.0, 1.0);
        
        float baseAngle = atan(p.y, p.x);
        float angle = mod(baseAngle + 3.14159 * 2.0 * uSymmetry, 6.28318 / uSymmetry);
        vec2 sp = vec2(cos(angle), sin(angle)) * l;
        
        float core = smoothstep(uInnerRadius, 0.0, l);
        
        for (int c = 0; c < 7; c++) {
            if (float(c) >= uNumPoints) break;
            float starAngle = float(c) * 6.28318 / uNumPoints + n * uRotationSpeed;
            
            vec2 star_p = sp;
            float rot = atan(star_p.y, star_p.x);
            float influence = cos(rot - starAngle) * 0.5 + 0.5;
            star_p = sp * (1.0 + influence * uStarInfluence);
            
            float local_l = length(star_p);
            float pulse = sin(n * uPulseRate) * 0.5 + 0.5;
            
            for (int i = 0; i < 70; i++) {
                if (float(i) >= uSymmetry * 20.0) break;
                float fi = float(i);
                float e = fi * 0.07 * sin(n) * cos(n - 3.5) + n * 2.0;
                vec2 pos = sin(fi * 0.111 * sin(n)) * vec2(sin(e), cos(e));
                float dist = length(star_p - pos * influence) + sin(n * 4.0 + fi * 0.033 + local_l) * 0.015;
                vec3 contribution = 0.003 / max(dist, 0.001) * 
                    (1.0 + cos(fi * 0.02 + local_l * 2.0 + 3.0 - n * 2.0 + 
                    vec3(uColorShift + uColorPhase, 
                         uColorShift + uColorPhase + 2.094, 
                         uColorShift + uColorPhase + 4.189)));
                O.rgb += contribution * (1.0 - fi / 70.0) * (influence * 0.5 + 0.5) * (pulse * 0.3 + 0.7);
            }
        }
        
        O.rgb += vec3(0.1, 0.2, 0.3) * smoothstep(1.0, 0.0, l) * uOuterGlow;
        O.rgb += vec3(1.0, 1.0, 0.9) * core;
        O.rgb = clamp(O.rgb * uIntensity, 0.0, 1.0);
        gl_FragColor = O;
    }
`;

// Helper functions
function generateSeed() {
    return Math.floor(Math.random() * 1000000);
}

function createRandom(seed) {
    return function() {
        seed = (seed * 16807) % 2147483647;
        return (seed - 1) / 2147483646;
    };
}

function weightedRandom(items, random) {
    const weights = items.map(item => item.weight);
    let total = weights.reduce((a, b) => a + b);
    let r = random() * total;
    
    for (let i = 0; i < items.length; i++) {
        if (r < weights[i]) return items[i];
        r -= weights[i];
    }
    return items[0];
}

function createShader(gl, type, source) {
    const shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error('Shader compilation error:', gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
    }
    return shader;
}

class StarGenerator {
    constructor(canvas) {
        this.currentHaikuIndex = Math.floor(Math.random() * haikus.length);
        this.canvas = canvas;
        this.gl = canvas.getContext('webgl');
        this.currentSeed = generateSeed();
        this.random = createRandom(this.currentSeed);
        this.params = {};
        
        if (!this.gl) {
            console.error('WebGL not supported');
            throw new Error('WebGL not supported');
        }
        
        this.initWebGL();
        this.initializeParameters();
        this.updateStarId();
        this.updateParamsDisplay();
        requestAnimationFrame(this.render.bind(this));
    }
    
    initWebGL() {
        const gl = this.gl;
        
        const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
        const fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);
        
        if (!vertexShader || !fragmentShader) {
            throw new Error('Shader creation failed');
        }
        
        this.program = gl.createProgram();
        gl.attachShader(this.program, vertexShader);
        gl.attachShader(this.program, fragmentShader);
        gl.linkProgram(this.program);
        
        if (!gl.getProgramParameter(this.program, gl.LINK_STATUS)) {
            console.error('Program linking error:', gl.getProgramInfoLog(this.program));
            throw new Error('Program linking error');
        }
        
        const positions = new Float32Array([
            -1, -1,
            1, -1,
            -1, 1,
            1, 1
        ]);
        
        const positionBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW);
        
        this.locations = {
            position: gl.getAttribLocation(this.program, 'aPosition'),
            time: gl.getUniformLocation(this.program, 'time'),
            resolution: gl.getUniformLocation(this.program, 'resolution'),
            numPoints: gl.getUniformLocation(this.program, 'uNumPoints'),
            speed: gl.getUniformLocation(this.program, 'uSpeed'),
            intensity: gl.getUniformLocation(this.program, 'uIntensity'),
            colorShift: gl.getUniformLocation(this.program, 'uColorShift'),
            rotationSpeed: gl.getUniformLocation(this.program, 'uRotationSpeed'),
            starInfluence: gl.getUniformLocation(this.program, 'uStarInfluence'),
            pulseRate: gl.getUniformLocation(this.program, 'uPulseRate'),
            symmetry: gl.getUniformLocation(this.program, 'uSymmetry'),
            innerRadius: gl.getUniformLocation(this.program, 'uInnerRadius'),
            outerGlow: gl.getUniformLocation(this.program, 'uOuterGlow'),
            colorPhase: gl.getUniformLocation(this.program, 'uColorPhase')
        };
        
        gl.useProgram(this.program);
        gl.enableVertexAttribArray(this.locations.position);
        gl.vertexAttribPointer(this.locations.position, 2, gl.FLOAT, false, 0, 0);
    }
    
    initializeParameters() {
        const pattern = weightedRandom(starPatterns, this.random);
        const scheme = weightedRandom(colorSchemes, this.random);
        
        this.params = {
            pattern: pattern.name,
            colorScheme: scheme.name,
            numPoints: Math.floor(this.random() * 5) + 3,
            speed: 0.2 + this.random() * 0.6,
            intensity: 0.15 + this.random() * 0.25,
            colorShift: scheme.colors[0],
            colorPhase: this.random() * 6.28318,
            rotationSpeed: 0.03 + this.random() * 0.15,
            starInfluence: 0.3 + this.random() * 0.4,
            pulseRate: 0.5 + this.random() * 2.0,
            symmetry: Math.floor(this.random() * 3) + 1,
            complexity: 30 + Math.floor(this.random() * 40),
            innerRadius: 0.2 + this.random() * 0.4,
            outerGlow: 0.5 + this.random() * 0.5
        };
    }
    
    getRarityScore() {
        let score = 0;
        score += this.params.numPoints >= 6 ? 20 : 0;
        score += this.params.pattern === "Binary" || this.params.pattern === "Pulsar" ? 25 : 0;
        score += this.params.symmetry === 3 ? 15 : 0;
        score += this.params.complexity > 60 ? 20 : 0;
        score += this.params.outerGlow > 0.8 ? 20 : 0;
        return score;
    }
    
    getRarityTier(score) {
        if (score >= 80) return "Legendary ★★★★★";
        if (score >= 60) return "Epic ★★★★";
        if (score >= 40) return "Rare ★★★";
        if (score >= 20) return "Uncommon ★★";
        return "Common ★";
    }
    
    updateStarId() {
        document.getElementById('starId').textContent = `Star #${String(this.currentSeed).padStart(6, '0')}`;
    }
    
    updateParamsDisplay() {
        const score = this.getRarityScore();
        const tier = this.getRarityTier(score);
        const paramsDiv = document.getElementById('params');
        paramsDiv.innerHTML = `
            <div class="param-row">Pattern: ${this.params.pattern}</div>
            <div class="param-row">Color Scheme: ${this.params.colorScheme}</div>
            <div class="param-row">Points: ${this.params.numPoints}</div>
            <div class="param-row">Symmetry: ${this.params.symmetry}x</div>
            <div class="param-row">Complexity: ${this.params.complexity}</div>
            <div class="rarity">Rarity: ${tier}</div>
        `;
    }
    
    resizeCanvasToDisplaySize() {
        const width = this.canvas.clientWidth;
        const height = this.canvas.clientHeight;
        
        if (this.canvas.width !== width || this.canvas.height !== height) {
            this.canvas.width = width;
            this.canvas.height = height;
            this.gl.viewport(0, 0, width, height);
        }
    }
    
    render(time) {
        this.resizeCanvasToDisplaySize();
        const gl = this.gl;
        
        gl.uniform1f(this.locations.time, time * 0.001);
        gl.uniform2f(this.locations.resolution, this.canvas.width, this.canvas.height);
        gl.uniform1f(this.locations.numPoints, this.params.numPoints);
        gl.uniform1f(this.locations.speed, this.params.speed);
        gl.uniform1f(this.locations.intensity, this.params.intensity);
        gl.uniform1f(this.locations.colorShift, this.params.colorShift);
        gl.uniform1f(this.locations.rotationSpeed, this.params.rotationSpeed);
        gl.uniform1f(this.locations.starInfluence, this.params.starInfluence);
        gl.uniform1f(this.locations.pulseRate, this.params.pulseRate);
        gl.uniform1f(this.locations.symmetry, this.params.symmetry);
        gl.uniform1f(this.locations.innerRadius, this.params.innerRadius);
        gl.uniform1f(this.locations.outerGlow, this.params.outerGlow);
        gl.uniform1f(this.locations.colorPhase, this.params.colorPhase);
        
        gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
        
        requestAnimationFrame(this.render.bind(this));
    }
    
    generateNewStar() {
        this.currentSeed = generateSeed();
        this.random = createRandom(this.currentSeed);
        this.initializeParameters();
        this.updateStarId();
        this.updateParamsDisplay();
        this.rotateHaiku();
    }
    
    async mintStar() {
        const mintButton = document.getElementById('mintButton');
        mintButton.disabled = true;
        mintButton.textContent = 'Minting...';
        
        try {
            // Here we would:
            // 1. Compress parameters
            // 2. Create metadata
            // 3. Call CAT-721 minting function
            await new Promise(resolve => setTimeout(resolve, 2000));
            
            mintButton.textContent = 'Star Minted!';
            setTimeout(() => {
                mintButton.textContent = 'Mint Star (10,000 sats)';
                mintButton.disabled = false;
            }, 3000);
        } catch (error) {
            console.error('Minting error:', error);
            mintButton.textContent = 'Minting Failed';
            setTimeout(() => {
                mintButton.textContent = 'Mint Star (10,000 sats)';
                mintButton.disabled = false;
            }, 3000);
        }
    }

    rotateHaiku() {
        this.currentHaikuIndex = (this.currentHaikuIndex + 1) % haikus.length;
        const haiku = haikus[this.currentHaikuIndex];
        const haikuDiv = document.getElementById('haiku');
        if (haikuDiv) {
            haikuDiv.innerHTML = haiku.lines.map(line => 
                `<div class="haiku-line">${line}</div>`
            ).join('');
        }
    }
}

// Initialize when the page loads
document.addEventListener('DOMContentLoaded', () => {
    const canvas = document.getElementById('glCanvas');
    if (canvas) {
        window.starGenerator = new StarGenerator(canvas);
        window.starGenerator.rotateHaiku(); // Display initial haiku
    }
});

// Expose functions for button clicks
window.generateNewStar = () => window.starGenerator?.generateNewStar();
window.mintStar = () => window.starGenerator?.mintStar();
