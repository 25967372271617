const moonPage = () => {
  return {
    init() {
      console.log('Moon page initializing...');
      this.createStars();
      this.getMoonInfo();
      setInterval(() => this.getMoonInfo(), 60000);
    },

    createStars() {
      const starField = document.getElementById('starField');
      if (!starField) {
        console.error('Star field element not found');
        return;
      }

      const numberOfStars = 200;
      for (let i = 0; i < numberOfStars; i++) {
        const star = document.createElement('div');
        star.className = 'star';
        
        const size = Math.random() * 2 + 1;
        star.style.width = `${size}px`;
        star.style.height = `${size}px`;
        
        star.style.left = `${Math.random() * 100}%`;
        star.style.top = `${Math.random() * 100}%`;
        
        star.style.setProperty('--twinkle-duration', `${Math.random() * 3 + 2}s`);
        
        starField.appendChild(star);
      }
    },

    getZodiacSign(date) {
      const zodiacRanges = [
        { sign: 'Capricorn', start: [12, 22], end: [1, 19] },
        { sign: 'Aquarius', start: [1, 20], end: [2, 18] },
        { sign: 'Pisces', start: [2, 19], end: [3, 20] },
        { sign: 'Aries', start: [3, 21], end: [4, 19] },
        { sign: 'Taurus', start: [4, 20], end: [5, 20] },
        { sign: 'Gemini', start: [5, 21], end: [6, 20] },
        { sign: 'Cancer', start: [6, 21], end: [7, 22] },
        { sign: 'Leo', start: [7, 23], end: [8, 22] },
        { sign: 'Virgo', start: [8, 23], end: [9, 22] },
        { sign: 'Libra', start: [9, 23], end: [10, 22] },
        { sign: 'Scorpio', start: [10, 23], end: [11, 21] },
        { sign: 'Sagittarius', start: [11, 22], end: [12, 21] }
      ];

      const month = date.getMonth() + 1;
      const day = date.getDate();

      // Special handling for Capricorn which spans year end
      if ((month === 12 && day >= 22) || (month === 1 && day <= 19)) {
        return 'Capricorn';
      }

      for (const range of zodiacRanges) {
        const [startMonth, startDay] = range.start;
        const [endMonth, endDay] = range.end;
        
        if (month === startMonth && day >= startDay || month === endMonth && day <= endDay) {
          return range.sign;
        }
      }

      return 'Unknown';
    },

    calculateMoonPhase(date) {
      // Known new moon date for reference (January 11, 2024 11:57 UTC)
      const knownNewMoon = Date.UTC(2024, 0, 11, 11, 57);
      const synodicMonth = 29.530588853; // Length of lunar month in days
      
      // Calculate days since known new moon
      const daysSinceNewMoon = (date.getTime() - knownNewMoon) / (1000 * 60 * 60 * 24);
      
      // Calculate current phase (0 to 1)
      let phase = (daysSinceNewMoon % synodicMonth) / synodicMonth;
      // Ensure phase is between 0 and 1
      phase = phase < 0 ? phase + 1 : phase;
      
      // Calculate moon age
      const moonAge = daysSinceNewMoon % synodicMonth;
      
      return {
        phase,
        age: moonAge,
        illumination: Math.abs(0.5 - (phase % 1)) * 2,
        phaseName: this.getPhaseNameFromValue(phase)
      };
    },

    getPhaseNameFromValue(phase) {
      const phaseNames = [
        'New Moon', 'Waxing Crescent', 'First Quarter', 
        'Waxing Gibbous', 'Full Moon', 'Waning Gibbous',
        'Last Quarter', 'Waning Crescent'
      ];
      const phaseIndex = Math.floor(phase * 8) % 8;
      return phaseNames[phaseIndex];
    },

    async getMoonInfo() {
      try {
        const now = new Date();
        console.log('Current date:', now.toISOString());
        
        // Start with calculated data
        let moonData = this.calculateMoonPhase(now);
        
        try {
          const timestamp = Math.floor(now.getTime() / 1000);
          console.log('Fetching from API with timestamp:', timestamp);
          const response = await fetch(`https://api.farmsense.net/v1/moonphases/?d=${timestamp}`);
          const data = await response.json();
          console.log('Raw API response:', data);
          
          if (data && data[0]) {
            moonData = {
              phase: this.getPhaseValueFromName(data[0].Phase),
              age: data[0].Age,
              illumination: data[0].Illumination,
              phaseName: data[0].Phase,
              distance: data[0].Distance
            };
            console.log('Using API moon data:', moonData);
          } else {
            console.warn('Invalid API data format, using calculated data');
          }
        } catch (error) {
          console.warn('Failed to fetch moon data:', error);
          console.log('Using calculated data instead');
        }
        
        // Update moon visualization
        this.updateMoonPhase(moonData.phase);
        
        // Update information displays
        this.updatePhaseInfo(moonData, now);
        this.updateZodiacInfo(now);
        this.updateLunarCalendars(now);
      } catch (error) {
        console.error('Error updating moon info:', error);
      }
    },

    getPhaseValueFromName(phaseName) {
      const phaseValues = {
        'New Moon': 0,
        'Waxing Crescent': 0.125,
        'First Quarter': 0.25,
        'Waxing Gibbous': 0.375,
        'Full Moon': 0.5,
        'Waning Gibbous': 0.625,
        'Last Quarter': 0.75,
        'Waning Crescent': 0.875
      };
      return phaseValues[phaseName] || 0;
    },

    updateMoonPhase(phase) {
      console.log('Updating moon phase visualization:', phase.toFixed(3));
      const moonDisc = document.querySelector('.moon-disc');
      
      if (!moonDisc) {
        console.error('Moon disc element not found');
        return;
      }

      // Calculate the position for the radial gradient
      let x;
      if (phase <= 0.5) {
        // Waxing (new to full) - illuminated part grows from right
        x = -150 + (phase * 300); // Start at -150% and move right
      } else {
        // Waning (full to new) - illuminated part shrinks from right
        x = 150 - ((phase - 0.5) * 300); // Start at 150% and move left
      }

      // Create a subtle light gradient for the moon's surface
      const lightGradient = `linear-gradient(
        120deg,
        #e6e6e6 0%,
        #f0f0f0 35%,
        #f0f0f0 65%,
        #e6e6e6 100%
      )`;

      // Set up the base moon with subtle shading
      moonDisc.style.background = `linear-gradient(
        120deg,
        rgb(0, 0, 0) 0%,
        rgb(0, 0, 0) 35%,
        rgb(104, 104, 104) 65%,
        rgb(230, 230, 230) 100%
      )`;
      moonDisc.style.boxShadow = `
        rgba(0, 0, 0, 0.4) -20px -20px 35px 0px inset,
        rgba(50, 50, 50, 0.2) 5px 5px 10px 0px inset
      `;
      
      // Create a sharp-edged mask with a pronounced curve
      const maskGradient = `radial-gradient(
        circle 500% at ${x}% 50%,
        black 49.7%,
        transparent 50%
      )`;
      
      moonDisc.style.maskImage = maskGradient;
      moonDisc.style.webkitMaskImage = maskGradient;
    },

    updatePhaseInfo(moonData, now) {
      console.log('Updating phase info:', moonData);

      const phaseName = document.getElementById('phaseName');
      if (phaseName) {
        phaseName.textContent = moonData.phaseName;
        console.log('Phase name:', moonData.phaseName);
      }
      
      const illumination = Math.round(moonData.illumination * 100);
      const illuminationEl = document.getElementById('illumination');
      if (illuminationEl) {
        const illuminationText = `${illumination}%`;
        illuminationEl.textContent = illuminationText;
        console.log('Illumination:', illuminationText);
      }
      
      const moonAgeEl = document.getElementById('moonAge');
      if (moonAgeEl) {
        const ageText = `${Math.round(moonData.age * 10) / 10}`;
        moonAgeEl.textContent = ageText;
        console.log('Moon age:', ageText);
      }
      
      const distanceEl = document.getElementById('moonDistance');
      if (distanceEl) {
        const distance = moonData.distance || 384400;
        const distanceText = `${Math.round(distance).toLocaleString()} km`;
        distanceEl.textContent = distanceText;
        console.log('Distance:', distanceText);
      }
      
      const daysInCycle = 29.53;
      const phase = moonData.phase;
      
      const daysToFullMoon = phase < 0.5 ? 
        (0.5 - phase) * daysInCycle : 
        (1.5 - phase) * daysInCycle;
      
      const daysToNewMoon = phase < 1 ? 
        (1 - phase) * daysInCycle : 
        (2 - phase) * daysInCycle;
      
      const fullMoonEl = document.getElementById('fullMoonCountdown');
      if (fullMoonEl) {
        const fullMoonText = `${Math.round(daysToFullMoon * 10) / 10} days`;
        fullMoonEl.textContent = fullMoonText;
        console.log('Days to full moon:', fullMoonText);
      }
      
      const newMoonEl = document.getElementById('newMoonCountdown');
      if (newMoonEl) {
        const newMoonText = `${Math.round(daysToNewMoon * 10) / 10} days`;
        newMoonEl.textContent = newMoonText;
        console.log('Days to new moon:', newMoonText);
      }
      
      const baseHour = (phase * 24) % 24;
      
      const moonrise = new Date(now);
      moonrise.setHours(baseHour, 0, 0);
      if (moonrise < now) moonrise.setDate(moonrise.getDate() + 1);
      
      const moonset = new Date(moonrise);
      moonset.setHours(moonrise.getHours() + 12);
      
      const timeFormat = { hour: '2-digit', minute: '2-digit', hour12: true };
      
      const moonriseEl = document.getElementById('moonrise');
      if (moonriseEl) {
        const moonriseText = moonrise.toLocaleTimeString([], timeFormat);
        moonriseEl.textContent = moonriseText;
        console.log('Moonrise:', moonriseText);
      }
      
      const moonsetEl = document.getElementById('moonset');
      if (moonsetEl) {
        const moonsetText = moonset.toLocaleTimeString([], timeFormat);
        moonsetEl.textContent = moonsetText;
        console.log('Moonset:', moonsetText);
      }
    },

    updateZodiacInfo(date) {
      const zodiacSign = this.getZodiacSign(date);
      console.log('Current zodiac sign:', zodiacSign);
      
      const signEl = document.getElementById('zodiacSign');
      if (signEl) {
        signEl.textContent = zodiacSign;
      }
      
      const constEl = document.getElementById('constellation');
      if (constEl) {
        constEl.textContent = zodiacSign;
      }
      
      // Calculate rough degree within sign (0-30)
      const day = date.getDate();
      const degree = Math.min(29, Math.round(day * (30/31)));
      
      const degreeEl = document.getElementById('zodiacDegree');
      if (degreeEl) {
        degreeEl.textContent = degree;
        console.log('Zodiac degree:', degree);
      }
    },

    gregorianToIslamicDate(date) {
      const jd = Math.floor((365.25 * (date.getFullYear() + 4716)) + 
                           (30.6001 * ((date.getMonth() + 1) + 1)) + 
                           date.getDate() + 2 - 
                           Math.floor(date.getFullYear() / 100) + 
                           Math.floor(Math.floor(date.getFullYear() / 100) / 4) - 1524);

      const l = jd - 1948440 + 10632;
      const n = Math.floor((l - 1) / 10631);
      const l1 = l - 10631 * n + 354;
      const j = Math.floor((10985 - l1) / 5316) * Math.floor((50 * l1) / 17719) + 
                Math.floor(l1 / 5670) * Math.floor((43 * l1) / 15238);
      const l2 = l1 - Math.floor((30 - j) / 15) * Math.floor((17719 * j) / 50) - 
                 Math.floor(j / 16) * Math.floor((15238 * j) / 43) + 29;
      
      const month = Math.floor((24 * l2) / 709);
      const day = l2 - Math.floor((709 * month) / 24);
      const year = 30 * n + j - 30;

      return { year, month, day };
    },

    updateLunarCalendars(now) {
      const chineseYear = 'Dragon';
      const chineseMonth = Math.floor(now.getMonth() / 2) + 1;
      const chineseEl = document.getElementById('chineseLunar');
      if (chineseEl) {
        const chineseText = `Year of the ${chineseYear}, Month ${chineseMonth}`;
        chineseEl.textContent = chineseText;
        console.log('Chinese calendar:', chineseText);
      }
      
      const islamicMonths = [
        'Muharram', 'Safar', 'Rabi Al Awwal', 'Rabi Al Thani',
        'Jumada Al Ula', 'Jumada Al Akhira', 'Rajab', 'Shaban',
        'Ramadan', 'Shawwal', 'Dhul Qadah', 'Dhul Hijjah'
      ];
      
      const islamicDate = this.gregorianToIslamicDate(now);
      const islamicMonth = islamicMonths[islamicDate.month];
      const islamicYear = islamicDate.year;
      const islamicEl = document.getElementById('islamicLunar');
      if (islamicEl) {
        const islamicText = `${islamicMonth} ${islamicYear} AH`;
        islamicEl.textContent = islamicText;
        console.log('Islamic calendar:', islamicText);
      }
      
      const hinduMonths = [
        'Chaitra', 'Vaisakha', 'Jyeshtha', 'Ashadha',
        'Shravana', 'Bhadrapada', 'Ashwina', 'Kartika',
        'Margashirsha', 'Pausha', 'Magha', 'Phalguna'
      ];
      const hinduMonth = hinduMonths[now.getMonth()];
      const paksha = now.getDate() < 15 ? 'Shukla' : 'Krishna';
      const hinduEl = document.getElementById('hinduLunar');
      if (hinduEl) {
        const hinduText = `${hinduMonth}, ${paksha} Paksha`;
        hinduEl.textContent = hinduText;
        console.log('Hindu calendar:', hinduText);
      }
    }
  };
};

export default moonPage;
